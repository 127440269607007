import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
//import {NaoAutorizadoComponent} from './screens/errors/nao-autorizado/nao-autorizado.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: 'atualizacao-cadastral'  ,
            loadChildren: () => import('./pages/recadastramento/recadastramento.module').then(m => m.RecadastramentoModule),
            data: { breadcrumb: 'Atualização Cadastral' }
        },
            { path: 'cadastro'  ,
            loadChildren: () => import('./pages/cadastrado-servidor/cadastro/cadastro.module').then(m => m.CadastroModule),
            data: { breadcrumb: 'Cadastro' }
        },
            { path: 'comprovante-recadastramento'  ,
            loadChildren: () => import('./pages/comprovante-recadastramento/comprovante-recadastramento.module').then(m => m.ComprovanteRecadastramentoModule),
            data: { breadcrumb: 'Comprovante' }
        },
            { path: 'verificacao'  ,
            loadChildren: () => import('./pages/autenticidade-documento/autenticidade-documento.module').then(m => m.AutenticidadeDocumentoModule),
            data: { breadcrumb: 'Verificação de Documentação' }
        },

        { path: 'recuperacao-conta'  ,
            loadChildren: () => import('./pages/recuperacao-conta/recuperacao-conta.module').then(m => m.RecuperacaoContaModule),
            data: { breadcrumb: 'Recuperação Conta' }
        },
            { path: 'consultar'  ,
            loadChildren: () => import('./pages/consulta-servidor/consulta-servidor.module').then(m => m.ConsultaServidorModule),
            data: { breadcrumb: 'Consultar' }
        },
            { path: 'cedulac'  ,
                loadChildren: () => import('./pages/relatorios/cedulac/cedulac.module').then(m => m.CedulaCModule),
                data: { breadcrumb: 'cédula C' }
        },
            { path: 'contracheque'  ,
            loadChildren: () => import('./pages/relatorios/contracheque/contracheque.module').then(m => m.ContraChequeModule),
            data: { breadcrumb: 'ContraCheque' }
        },
            { path: 'solicitacao-cartao'  ,
              loadChildren: () => import('./pages/cartao-servidor/solicitacao/solicita-cartao/solicita-cartao.module').then(m => m.SolicitaCartaoModule),
              data: { breadcrumb: 'Solicitção de Cartão do Servidor' }
        },
            { path: 'liberacao-cartao'  ,
              loadChildren: () => import('./pages/cartao-servidor/liberacao/liberacao-cartao/liberacao-cartao.module').then(m => m.LiberacaoCartaoModule),
              data: { breadcrumb: 'Liberação de Cartão do Servidor' }
        },
            { path: 'consulta-cartao'  ,
              loadChildren: () => import('./pages/cartao-servidor/consulta/consulta-cartao/consulta-cartao.module').then(m => m.ConsultaCartaoModule),
              data: { breadcrumb: 'Consulta Cartão do Servidor' }
        },

            { path: 'users'  ,
             loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
             data: { breadcrumb: 'Usuarios' }
        },

            { path: 'blank'  ,
             component: BlankComponent, data: { breadcrumb: 'Blank page' }
        }        ]
    },

    {path: '', redirectTo: 'login', pathMatch: 'full'}, // acho que é o correto!
    {path: 'login', loadChildren: () => import('./seguranca/seguranca.module').then(m => m.SegurancaModule)},
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    //{path: 'nao-autorizado', component: NaoAutorizadoComponent},
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
             preloadingStrategy: PreloadAllModules,  // <- comente esta linha para ativar o carregamento lento
             useHash: true
        })
        //RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
