export class Utils {

  static formataStrings(dado) {

    // retira os caracteres indesejados...
    dado = dado.replace(/[^\d]/g, '');

    return dado;
  }

  static trataEmail(email: string): string {
    let emailMinusculo = email.toLowerCase();
    let emailTratado = emailMinusculo.trim();

    return emailTratado;
}

}


