import { AbstractControl } from "@angular/forms";
// Importação somente da validação
import { isPIS, isCNH, isTituloEleitor  } from 'validation-br'


export class Validacoes {

  static validaCpf(controle: AbstractControl) {
    let cpf;

    if ( controle.value != null){
       cpf = controle.value.replace(/[^\d]+/g, '');
    }else{
       cpf = controle.value;
    }

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');

    if (
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      !regex.test(cpf)
    )
      valido = false;
    else {
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) valido = false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) valido = false;
      valido = true;
    }

    if (valido) return null;

    return { cpfInvalido: true };
  }

  static validaCNH(controle: AbstractControl){

    if (isCNH(controle.value) == true){
      return null;
    }else{
      return { invalidaCnh: true };
    }
  }

  static verificaIdade(controle: AbstractControl){
    if (controle.value === "01/01/1970"){
      return { invalidaCnh: true };
    }else{
      return null;
    }
  }

  static validaTituloEleitor(controle: AbstractControl){

    if ( controle.value != null){

      var paddedInsc = controle.value;
      var dig1 = 0;
      var dig2 = 0;

      var tam = paddedInsc.length;
      var digitos = paddedInsc.substr(tam - 2, 2);
      var estado  = paddedInsc.substr(tam - 4, 2);
      var titulo  = paddedInsc.substr(0, tam - 2);
      var exce = (estado == '01') || (estado == '02');
      dig1 = (titulo.charCodeAt(0) - 48) * 9 + (titulo.charCodeAt(1) - 48) * 8 +
            (titulo.charCodeAt(2) - 48) * 7 + (titulo.charCodeAt(3) - 48) * 6 +
            (titulo.charCodeAt(4) - 48) * 5 + (titulo.charCodeAt(5) - 48) * 4 +
            (titulo.charCodeAt(6) - 48) * 3 + (titulo.charCodeAt(7) - 48) * 2;
      var resto = (dig1 % 11);
      if (resto == 0) {
        if (exce){
          dig1 = 1;
        } else {
          dig1 = 0;
        }
      } else {
        if (resto == 1) {
          dig1 = 0;
        } else {
          dig1 = 11 - resto;
        }
      }

      dig2 = (titulo.charCodeAt(8) - 48) * 4 + (titulo.charCodeAt(9) - 48) * 3 + dig1 * 2;
      resto = (dig2 % 11);
      if (resto == 0) {
        if (exce) {
          dig2 = 1;
        } else {
          dig2 = 0;
        }
      } else {
        if (resto == 1){
          dig2 = 0;
        }else{
          dig2 = 11 - resto;
        }
      }

      if ( (digitos.charCodeAt(0) - 48 == dig1) && (digitos.charCodeAt(1) - 48 == dig2) ) {
        return null; // Titulo valido
      } else {
        return { tituloInvalido: true };
      }
    }
   /*  if (isTituloEleitor(controle.value) == true){
      return null;
    }else{
      return { tituloInvalido: true };
    } */
  }

  static validaPIS(controle: AbstractControl){

    if (isPIS(controle.value) == true){
      return null;
    }else{
      return { pisInvalido: true };
    }
  }

  static validaDataExpedicao(controle: AbstractControl) {

    let data = controle.value;
    var strData = controle.value;

    if(data != null || data != undefined){
      var dia  = data.split("/")[0];
      var mes  = data.split("/")[1];
      var ano  = data.split("/")[2];

      data = ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);

      var partesData = strData.split("/");
      var dataExpedicao = new Date(partesData[2], partesData[1] - 1, partesData[0]);

      const today = new Date();
      const birthDate = new Date(data);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      if(dataExpedicao > new Date() || age > 60 ){
          return { dataInvalida: true};
      }else{
          return null;
      }
    }
  }

  static getAge(controle: AbstractControl) {

    let data = controle.value;
    var strData = controle.value;

    if(data != null || data != undefined){
      var dia  = data.split("/")[0];
      var mes  = data.split("/")[1];
      var ano  = data.split("/")[2];

      data = ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);

      var partesData = strData.split("/");
      var novaData = new Date(partesData[2], partesData[1] - 1, partesData[0]);

      const today = new Date();
      const birthDate = new Date(data);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      if(age > 130 || novaData > new Date()){
          return { idadeInvalida: true};
      }else{
          return null;
      }
    }
  }

  static getAgeDependente(controle: AbstractControl) {

    let data = controle.value;
    var strData = controle.value;

    if(data != null || data != undefined){
      var dia  = data.split("/")[0];
      var mes  = data.split("/")[1];
      var ano  = data.split("/")[2];

      data = ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);

      const today = new Date();
      const birthDate = new Date(data);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      var partesData = strData.split("/");
      var novaData = new Date(partesData[2], partesData[1] - 1, partesData[0]);

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      if(age > 130 || novaData > new Date()){
          return { idadeInvalida: true};
      }else{
          return null;
      }
    }
  }


  static validaRequeridoReservista(sexo: any){

    if(sexo != null || sexo != undefined){
    }else{

    }

    /* if(sexo == 1){
      return 'requerido';
    } */
  }





}
