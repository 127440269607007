import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CidadeService {

  cidadeUrl: string;

  constructor(private http: HttpClient) {
    this.cidadeUrl = `${environment.apiUrl}/cidades`;
  }

  buscarCidades(){
    return this.http.get(`${this.cidadeUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

}
