import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {NotificacaoService} from './message/notificacao.service';
import {NotAuthenticatedError} from '../seguranca/sgg.http-interceptor';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor(
        private messageService: NotificacaoService,
        private router: Router) { }

    handle(errorResponse: any): void {

        let msg: string;

        if (typeof errorResponse === 'string') {
            msg = errorResponse;

        } else if (errorResponse instanceof NotAuthenticatedError) {
            msg = 'Sua sessão expirou!';
            this.router.navigate(['/login']);

        } else if (errorResponse instanceof HttpErrorResponse
            && errorResponse.status >= 400 && errorResponse.status <= 499) {
            //let errors;
            msg = 'Ocorreu um erro ao processar a sua solicitação';

            if (errorResponse.status === 403) {
                msg = 'Você não tem permissão para executar esta ação';
            }

            if (errorResponse.status === 401) {
              msg = 'Não autorizado. Procure o seu RH ou a SEGAD';
            }

           /*  try {
                errors = errorResponse.json();

                msg = errors[0].mensagemUsuario;
            } catch (e) { }

            console.error('Ocorreu um erro', errorResponse); */

        } else {
            msg = 'Erro ao processar serviço remoto. Tente novamente.';
            // console.error('Ocorreu um erro', errorResponse);
        }

//    this.messageService.add({ severity: 'error', detail: msg });
        this.messageService.showMessage(msg);
    }

}
