import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdentificacaoTipoService {

  identificacaoTipoUrl: string;

  constructor(private http: HttpClient) {
    this.identificacaoTipoUrl = `${environment.apiUrl}/identificacaotipo`;
  }

  buscarIdentificacaoTipos(){
    return this.http.get(`${this.identificacaoTipoUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

}
