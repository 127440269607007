import {Component, Inject, OnInit} from '@angular/core';
import {NotificacaoService} from '../message/notificacao.service';
import {AuthService} from '../../seguranca/auth.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
//import * as moment from 'moment';
import {ErrorHandlerService} from '../error-handler.service';
//import {HistoricoFilter, HistoricoProvimentoService} from '../../screens/historico-provimento/historico-provimento.service';

export interface TimelineHistorico {
  titulo: string;
  servidor: string;
  dataNomeExone: string;
  dataNomeExoneEfeito: string;
  nomExonDecretoNumero: string;
  nomExonDoeNumero: string;
  nomExonDoeData: string;
  cor: string;
  place: string;
  foto: string;
}


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  timelineHistorico: TimelineHistorico[] = [];

  funcao = '';
/*
  filtro = new HistoricoFilter();

  constructor(private historicoService: HistoricoProvimentoService,
              private notificacaoService: NotificacaoService,
              public authService: AuthService,
              private router: Router,
              public dialogo: MatDialog,
              public dialogRef: MatDialogRef<TimelineComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogHistoricoData,
              private errorHandler: ErrorHandlerService
  ) {
  }
*/
  ngOnInit(): void {
    const self = this;
    this.pesquisar();
  }

  pesquisar(): void {
/*
    this.filtro.codigoProvimento = this.data.codigo;
    this.historicoService
      .pesquisar(this.filtro)
      .then((resultado) => {
        let size = resultado['content'].length;

        for (const item of resultado['content']) {
          this.funcao = item['funcao'];
          if (size % 2 === 1) {
            size -= 1;
            this.timelineHistorico.push({
              titulo: item['tipoProvimento'],
              servidor: item['servidorNome'],
              dataNomeExone: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoData']).format('DD/MM/YYYY') : moment(item['exoneracaoData']).format('DD/MM/YYYY'),
              dataNomeExoneEfeito: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoEfeitoData']).format('DD/MM/YYYY') : moment(item['exoneracaoEfeitoData']).format('DD/MM/YYYY'),
              nomExonDecretoNumero: item['tipoProvimento'] !== 'Exoneração' ? item['nomeacaoDecretoNumero'] : item['exoneracaoDecretoNumero'],
              nomExonDoeNumero: item['tipoProvimento'] !== 'Exoneração' ? item['nomeacaoDoeNumero'] : item['exoneracaoDoeNumero'],
              nomExonDoeData: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoDoeData']).format('DD/MM/YYYY') : moment(item['exoneracaoDoeData']).format('DD/MM/YYYY'),
              cor: item['tipoProvimento'] !== 'Exoneração' ? '#3f51b5' : '#ff0000',
              place: 'right',
              foto: item['foto']
            });
          } else {
            size -= 1;
            this.timelineHistorico.push({
              titulo: item['tipoProvimento'],
              servidor: item['servidorNome'],
              dataNomeExone: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoData']).format('DD/MM/YYYY') : moment(item['exoneracaoData']).format('DD/MM/YYYY'),
              dataNomeExoneEfeito: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoEfeitoData']).format('DD/MM/YYYY') : moment(item['exoneracaoEfeitoData']).format('DD/MM/YYYY'),
              nomExonDecretoNumero: item['tipoProvimento'] !== 'Exoneração' ? item['nomeacaoDecretoNumero'] : item['exoneracaoDecretoNumero'],
              nomExonDoeNumero: item['tipoProvimento'] !== 'Exoneração' ? item['nomeacaoDoeNumero'] : item['exoneracaoDoeNumero'],
              nomExonDoeData: item['tipoProvimento'] !== 'Exoneração' ? moment(item['nomeacaoDoeData']).format('DD/MM/YYYY') : moment(item['exoneracaoDoeData']).format('DD/MM/YYYY'),
              cor: item['tipoProvimento'] !== 'Exoneração' ? '#3f51b5' : '#ff0000',
              place: 'left',
              foto: item['foto']
            });
          }
        }



      })
      .catch((erro) => this.errorHandler.handle(erro));
  */
    }

  print(): void {
    const list = document.getElementById('print');



    //  list.innerHTML
    //);
// Open used in new window
    const popupWin = window.open('', '_blank',);
    popupWin.document.open();
    popupWin.document.write('<html> + document.head + <body onload="window.print()">' + list.innerHTML + '</body></html>');
    popupWin.document.close();
//    window.print();
  }

  printDocument(documentName: string, documentData: string[]): void {
/*    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }]);
*/
  }

}

export interface DialogHistoricoData {
  codigo: number;
}
