import { Menu } from './menu.model';


export const verticalMenuItems= [
    new Menu (3 , 'Atualização Cadastral', '/atualizacao-cadastral', null , 'assignment', null, false, 0, 'ROLE_RECADASTRAMENTO'),
    new Menu (17 , 'Emitir comprovante', '/comprovante-recadastramento', null , 'article', null, false, 0, 'ROLE_RECADASTRAMENTO'),
    new Menu (5 , 'Cadastro Servidor', '/cadastro', null , 'group_add', null, false, 0, 'ROLE_RESETA_USUARIO'),
    new Menu (6 , 'Consultar Servidor', '/consultar', null , 'search', null, false, 0, 'ROLE_RESETA_USUARIO'),
    new Menu (53, 'ContraCheque'       , '/contracheque'   , null , 'attach_money'      , null, false, 0, 'ROLE_RECADASTRAMENTO'),
    new Menu (16, 'Cedula C', '/cedulac', null , 'pets', null, false, 0, 'ROLE_RECADASTRAMENTO'),
    /* new Menu (55, 'Cartão do Servidor', '/', null , 'supervisor_account', null, false, 0, 'ROLE_RESETA_USUARIO'), */
    new Menu (56, 'Solicitar Cartão Servidor', '/solicitacao-cartao', null , 'credit_card', null, false, 0, 'ROLE_RECADASTRAMENTO'),
    new Menu (57, 'Liberar Cartão', '/liberacao-cartao', null , 'add_task', null, false, 0, 'ROLE_AUTORIZA_CARTAO'),
    new Menu (58, 'Consultar Cartão', '/consulta-cartao', null , 'card_membership', null, false, 0, 'ROLE_RECADASTRAMENTO'),
    new Menu (54, 'Usuários', '/users', null , 'supervisor_account', null, false, 0, 'ROLE_RESETA_USUARIO'),
    //...(false? [] : [new Menu (56, 'Android', '/', null , 'android', null, false, 0, 'ROLE_RESETA_USUARIO')])
  ]

export const horizontalMenuItems = [
  new Menu (3 , 'Atualização Cadastral', '/atualizacao-cadastral', null , 'person'            , null, false, 0, 'ROLE_RECADASTRAMENTO'),
  new Menu (17 , 'Emitir comprovante', '/comprovante-recadastramento', null , 'article', null, false, 0, 'ROLE_RECADASTRAMENTO'),
  new Menu (5 , 'Cadastro Servidor', '/cadastro'     , null , 'group_add'            , null, false, 0, 'ROLE_RESETA_USUARIO'),
  new Menu (6 , 'Consultar Servidor', '/consultar'     , null , 'person'            , null, false, 0, 'ROLE_RESETA_USUARIO'),
  //new Menu (53, 'ContraCheque', '/contra-cheque'   , null , 'attach_money'      , null, false, 0, 'ROLE_EMITE_CONTRACHEQUE'),
  //new Menu (16, 'Cedula C', '/cedulaC'   , null , 'attach_money'      , null, false, 0, 'ROLE_RECADASTRAMENTO'),
  new Menu (54, 'Usuários', '/users'          , null , 'supervisor_account', null, false, 0, 'ROLE_RESETA_USUARIO')
]


