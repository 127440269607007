import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EscolaridadeService {

  escolaridadelUrl: string;

  constructor(private http: HttpClient) {
    this.escolaridadelUrl = `${environment.apiUrl}/grauinstrucao`;
  }

  buscarEscolaridade(){
    return this.http.get(`${this.escolaridadelUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
