import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SexoService {

  sexoUrl: string;

  constructor(private http: HttpClient) {
    this.sexoUrl = `${environment.apiUrl}/sexos`;
  }

  buscarSexo(){
    return this.http.get(`${this.sexoUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
