import { RecadastramentoService } from './../../recadastramento/recadastramento.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { ContraChequeService } from './contracheque.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';


export interface dadosCC {
  orgao: string;
  cpf: string;
  matricula: string;
  periodo: string;
  cod_folha: number;
  nom_folha: string;
}


@Component({
  selector: 'app-contracheque',
  templateUrl: './contracheque.component.html',
  styleUrls: ['./contracheque.component.scss'],
  animations: [
    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(300)),
    ]),
  ],
})
export class ContraChequeComponent implements OnInit {

  flagMostrarMsgNaoRecadastrado = false;
  flagNaoMostrarMsgNaoRecadastrado = true;

  exibirTabela = true;
  isLoading = false;

  cnpjGov = "84012012000126";
  dadosContraCheque: dadosCC[] = [];

  displayedColumns: string[] = ['orgao', 'cpf', 'matricula', 'periodo', 'cod_folha', 'nom_folha', 'acoes'];
  dataSourceCC = new MatTableDataSource<dadosCC>(this.dadosContraCheque);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  registrationFormCC: FormGroup;
  usuarioLogado: string = '';

  buscaCCForm: FormGroup<{
    cpfTitular: FormControl<string>
  }>


  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private contraChequeService: ContraChequeService,
    private recadastramentoService: RecadastramentoService
    ) {
      this.buscaCCForm = this.formBuilder.group({
        cpfTitular: new FormControl('', {validators:[Validators.nullValidator], nonNullable: true})
      })
    }

    ngOnInit(): void {
      this.usuarioLogado = this.auth.jwtPayload?.user_name;
      this.buscaDadosContraCheque(this.usuarioLogado);
      this.verificaIdades();

      this.registrationFormCC = this.formBuilder.group({
        matricula: [null, Validators.required],
        periodoInicial: [null, Validators.required],
        periodoFinal: [null, Validators.required],
        folha: [null, Validators.required],
      });
    }

    ngAfterViewInit() {
      this.dataSourceCC.paginator = this.paginator;
    }

    geraContraCheque(row: any){
      this.isLoading = true;
      return this.contraChequeService.geraContraChequePf(row.cnpj, row.cpf, row.matricula, row.periodo_financeiro, row.cod_folha)
      .then(contraCheque => {
        this.isLoading = false;
        const url = window.URL.createObjectURL(contraCheque);
        window.open(url);
      });
    }

    pesquisarCCPorCpf() {
      this.buscaDadosContraCheque(this.buscaCCForm.value.cpfTitular)
    }


    buscaDadosContraCheque(cpfServidor: string){
      this.isLoading = true;
      this.exibirTabela = false;
      return this.contraChequeService.buscaDadosContraChequePf(this.cnpjGov, cpfServidor)
      .then(dadosCC => {
        this.isLoading = false;
        this.exibirTabela = true;
        this.dadosContraCheque =  dadosCC;
        this.dataSourceCC.data = this.dadosContraCheque;
      });
    }

    verificaIdades() {
      return this.recadastramentoService.verificarIdades(this.usuarioLogado)
      .then(status => {
        if(status) {
          this.flagMostrarMsgNaoRecadastrado = true;
          this.flagNaoMostrarMsgNaoRecadastrado = false;
        }else{
          this.flagMostrarMsgNaoRecadastrado = false;
          this.flagNaoMostrarMsgNaoRecadastrado = true;
        }
      });
    }

    temPermissao(permissao: string) {
      return this.auth.temPermissao(permissao);
    }

}
