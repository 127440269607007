import { SnackBarService } from 'src/app/servicos_utilitarios/snack-bar.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NovaSenha } from 'src/app/domain/NovaSenha';
import { UsuarioAux } from 'src/app/domain/UsuarioAux';
import { AuthService } from 'src/app/seguranca/auth.service';
import { UsuarioAuxService } from 'src/app/servicos_utilitarios/usuario-aux.service';

@Component({
  selector: 'app-reset-senha-form',
  templateUrl: './reset-senha-form.component.html',
  styleUrls: ['./reset-senha-form.component.scss']
})
export class ResetSenhaFormComponent implements OnInit {

  novaSenha = new NovaSenha();
  usuarioAux = new UsuarioAux();
  flagMessage = false;
  ocultarDicaDeSenha = true;

  usuarioLogado: string = '';

  hide = true;
  hideRepeat = true;

  registrationForm: FormGroup<{
    novaSenha: FormControl<string>,
    repeticaoSenha: FormControl<string>,
  }>

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private usuarioAuxService: UsuarioAuxService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<ResetSenhaFormComponent>,
  ) {
    this.registrationForm = this.formBuilder.group({
      novaSenha: new FormControl('', { validators: [Validators.required], nonNullable: true }),
      repeticaoSenha: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    });
  }

  ngOnInit() {
    this.usuarioLogado = this.auth.jwtPayload?.user_name;
  }


  salvar() {
    this.novaSenha.novaSenha = this.registrationForm.value.novaSenha;
    this.novaSenha.repeticaoSenha = this.registrationForm.value.repeticaoSenha;

    this.usuarioAux.key = this.novaSenha.novaSenha;

    if (this.verificaIgualdadeDeSenhas() && this.senhaValida(this.novaSenha.novaSenha) ) {
      this.usuarioAuxService.updateSenhaUsuario(this.usuarioLogado, this.usuarioAux)
        .then(() => {
          this.dialogRef.close();
          this.snackBarService.sucess('Senha alterada com sucesso')
        })
    } else {
      this.ocultarDicaDeSenha = false
      this.flagMessage = true;
    }

  }

  getMessageUser() {
    this.flagMessage = false;
    this.ocultarDicaDeSenha = true;
    return 'As senhas não conferem ou não atendem as regras de formação da senha. Por favor, digite novamente.';
  }



  verificaIgualdadeDeSenhas(){
    return this.novaSenha.novaSenha == this.novaSenha.repeticaoSenha
  }

  senhaValida(p: string) {
    var retorno = false;
    var letrasMaiusculas = /[A-Z]/;
    var letrasMinusculas = /[a-z]/;
    var numeros = /[0-9]/;
    var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    if (p.length < 8) {
      return retorno;
    }

    var auxMaiuscula = 0;
    var auxMinuscula = 0;
    var auxNumero = 0;
    var auxEspecial = 0;

    for (var i = 0; i < p.length; i++) {
      if (letrasMaiusculas.test(p[i]))
        auxMaiuscula++;
      else if (letrasMinusculas.test(p[i]))
        auxMinuscula++;
      else if (numeros.test(p[i]))
        auxNumero++;
      else if (caracteresEspeciais.test(p[i]))
        auxEspecial++;
    }
    if (auxMaiuscula > 0) {
      if (auxMinuscula > 0) {
        if (auxNumero > 0) {
          if (auxEspecial) {
            retorno = true;
          }
        }
      }
    }
    return retorno;
  }


}
