import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoService {

  private mensageiro = new Subject<void>();

  constructor() { }

  emitirMensagem() : void {
    this.mensageiro.next();
  }

  receberMensagem() : Observable<void> {
    return this.mensageiro.asObservable();
  }
}
