import { RacaCor } from './RacaCor';
//import { Cidade } from "./Cidade";
import { Estado } from "./Estado";
import { EstadoCivil } from "./EstadoCivil";
import { GrauInstrucao } from "./GrauInstrucao";
import { IdentificacaoOrgao } from "./IdentificacaoOrgao";
import { IdentificacaoTipo } from "./IdentificacaoTipo";
import { Nacionalidade } from "./Nacionalidade";
import { Sexo } from './Sexo';
import { Logradouro } from './Logradouro';
import { CnhCategoria } from './CnhCategoria';
import { TipoSanguineo } from './TipoSanguineo';
import { UsuarioRole } from './UsuarioRole';
import { PessoaJuridica } from './PessoaJuridica';
//import { Escolaridade } from './Escolaridade';

export class PessoaFisica {

  cpf: string;
  codCasadoComBrasileiro: Boolean;
  codDeficienciaAuditiva:  Boolean;
  codDeficienciaCota: Boolean;
  codDeficienciaFisica: Boolean;
  codDeficienciaIntelectual: Boolean;
  codDeficienciaMental: Boolean;
  codDeficienciaReabilitado: Boolean;
  codDeficienciaVisual: Boolean;
  codFilhoBrasileiro: Boolean;
  codResidenciaPropria: Boolean;
  codResidenciaPropriaFgts: Boolean;
  codResidenteExterior: Boolean;
  ctpsDataEmissao: string;
  ctpsNumero: string;
  ctpsSerie: string;
  datChegadaBrasil: string;
  datNascimento: Date;
  datNaturalizacao: Date;
  endCep: string;
  endNumero: string;
  endComplemento: string;
  ideDataEmissao: Date;
  ideDataValidade: Date;
  ideNumero: string;
  maeNome: string;
  nis: string;
  nome: string ;
  paiNome: string;
  nomNaturalidadeCidade: string;
  estadoCtpsUf = new Estado();
  estadoIdentificacaoUf = new Estado();
  estadoNaturalidadeUf = new Estado();
  estadoCivil = new EstadoCivil ();
  grauInstrucao = new GrauInstrucao();
  identificacaoTipo = new IdentificacaoTipo();
  identificaoOrgao = new IdentificacaoOrgao();
  identificacaoDataValidade: string;
  //logradouro: string;
  bairroSa: string;
  logradouroSa: string;
  anoFimPrimeiroEmprego : number;
  cnhNumero : string;
  cnhValidade : Date;
  cnhDataExpedicao : Date;
  cnhDataPrimeiraHabilitacao : Date;
  cnhExpedicaoUf = new Estado();
  cnhCategoria = new CnhCategoria();
  reservistaNumero : string;
  reservistaOrgao : string;
  reservistaCategoria : string;
  reservistaAnoReserva : number;
  reservistaDataExpedicao : Date;
  reservistaExpedicaoUf = new Estado();
  registroNacionalEstrangeiro : string;
  tituloEleitorNumero : string;
  tituloDataEmissao : Date;
  tituloSecao : string;
  tituloZona : string;
  codTituloUf = new Estado();
  nomTituloCidade: string;
  datLimitePermanencia : Date;
  codTipoSanguineo = new TipoSanguineo();
  primeiroEmpregoAno : number;
  nacionalidade = new Nacionalidade();
  racaCor = new RacaCor();
  sexo = new Sexo();
  dataNaturalizacao: Date;
  dataChegada: Date;
  usuarioRoles = new Array<UsuarioRole>();
  pessoaJuridica = new Array<PessoaJuridica>();
  //escolaridade = new Escolaridade();
}
