import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';


import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RecadastramentoComponent } from './recadastramento.component';

import {OverlayService} from '../../core/progress-spinner/overlay.service';

//import { TextMaskModule } from 'angular2-text-mask';

//mascaras
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;


export const routes: Routes = [
  { path: '', component: RecadastramentoComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({

      dropSpecialCharacters: false // false salva com mascara true salva sem
    }),


  ],
  declarations: [
    RecadastramentoComponent
  ],
  providers: [
      OverlayService
  ]
})


export class RecadastramentoModule { }



