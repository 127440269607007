import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CedulaCService {

  servidorUrl: string;

  constructor(private http: HttpClient) {
    this.servidorUrl = `${environment.apiUrl}`;
  }

  verificaRecadastramento(cpf: string){
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/pessoasfisicas/verificar-recadastramento?`, {params})
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  geraCedulaCPf(cpf: string, codDirf: number){
    let params = new HttpParams();
    params = params.set('cpf', cpf);
    params = params.set('codDirf', codDirf);

    return this.http.get(`${this.servidorUrl}/dirf/download/cedulaC?`, {params, responseType: 'blob' })
      .toPromise();
  }

  buscaDirf(cpf: string){
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/dirf/buscaDirf?`, {params})
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
