
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { AuthGuard } from './auth.guard';
import { SegurancaRoutingModule } from './seguranca-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { environment } from '../../environments/environment.prod';
import { environment } from '../../environments/environment';
import { SggHttpInterceptor} from './sgg.http-interceptor';
import {JwtHelperService, JwtModule} from '@auth0/angular-jwt';
//import {NgxMaskModule} from 'ngx-mask';
import {SharedModule} from '../shared/shared.module';
import {LoginComponent} from './login/login.component';

@NgModule({
  declarations: [LoginComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            return localStorage.getItem('token');
          },
          // rotas permitidas
         // allowedDomains:  environment.tokenWhitelistedDomains,
         allowedDomains:  ['192.168.1.4:8089'], //do financeiro
         //allowedDomains:  ['10.57.150.109:8080'], //do financeiro
          // rotas não permitidas
          disallowedRoutes: environment.tokenBlacklistedRoutes,
        }
      }),
       // NgxMaskModule,
        SharedModule,
        SegurancaRoutingModule,
    ],
  providers: [
    AuthGuard,
    JwtHelperService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: SggHttpInterceptor,
        multi: true
    },

  ]
})
export class SegurancaModule { }
