import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/seguranca/auth.service';
import { CedulaCService } from './cedulac.service';
import { Utils } from 'src/app/util/Utils';
import { SnackBarService } from 'src/app/servicos_utilitarios/snack-bar.service';
import { RecadastramentoService } from '../../recadastramento/recadastramento.service';

export interface dadosCedulaC {
  nome: string
  cpf: string;
  fontePagadora: string;
  calendarioExercicio: string;
  //acao: string;
}

@Component({
  selector: 'app-cedulac',
  templateUrl: './cedulac.component.html',
  styleUrls: ['./cedulac.component.scss']
})
export class CedulaCComponent implements OnInit {

  flagMostrarMsgNaoRecadastrado = false;
  flagNaoMostrarMsgNaoRecadastrado = false;

  cpfServidor: string;
  usuarioLogado: string = '';
  mostrarTabelaCedulaPessoal = false;
  mostrarTabelaCedulaTerceiro = false;
  mostrarPesquisa=false;

  dadosCedulaC: dadosCedulaC[] = [];

  displayedColumnsCedulaC: string[] = ['nome', 'cpf', 'fontePagadora', 'calendarioExercicio', 'acao'];
  dataSourceCedulaC = new MatTableDataSource<dadosCedulaC>(this.dadosCedulaC);

  servidorForm: FormGroup<{
    cpfServidor: FormControl<string>
  }>

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private cedulaCService: CedulaCService,
    private recadastramentoService: RecadastramentoService,
    private snackBarService : SnackBarService
  ) {
    this.servidorForm = this.formBuilder.group({
      cpfServidor: new FormControl('', {validators:[Validators.nullValidator], nonNullable: true})
    })
  }

  ngOnInit(): void {
    this.usuarioLogado = this.auth.jwtPayload?.user_name;
    this.buscaCedulaCPessoal();
    this.verificaIdades();
  }

  carregarServidor(){
    let cpf = Utils.formataStrings(this.servidorForm.value.cpfServidor);
    return this.recadastramentoService.verificarIdades(cpf.trim())
    .then(status => {
      if(status) {
        this.snackBarService.error("Ops, algo deu errado! O servidor está pendente de recadastramento, por favor, atualizar o cadastro.")
      }else{
        this.mostrarTabelaCedulaTerceiro = false;
        this.cpfServidor = Utils.formataStrings(this.servidorForm.value.cpfServidor);
        this.consultaDirfTerceiro(this.cpfServidor);
        this.mostrarTable();
      }
    })
    .catch(() => {
      this.snackBarService.error('Servidor não encontrado na base de dados')
    })

  }

  mostrarTable(){
    this.mostrarTabelaCedulaTerceiro = true;
  }

  buscaCedulaCPessoal(){
    this.mostrarTabelaCedulaPessoal = true;
    this.consultaDirfPf();
  }

  pesquisaServidor(){
    this.mostrarPesquisa = true;

    if(this.mostrarTabelaCedulaPessoal== true || this.mostrarTabelaCedulaTerceiro== true ){
      this.mostrarTabelaCedulaPessoal = false;
      this.mostrarTabelaCedulaTerceiro = false;
    }
  }

  geraCedulaC(row: any){
    return this.cedulaCService.geraCedulaCPf(row.cpf, row.codDirf)
      .then(cedulaC => {
        const url = window.URL.createObjectURL(cedulaC);

        window.open(url);
      });
  }

  consultaDirfTerceiro(cpfServidor: string){
    return this.cedulaCService.buscaDirf(cpfServidor)
      .then(dirf => {
        this.dataSourceCedulaC = dirf;
      })

  }

  consultaDirfPf(){
    return this.cedulaCService.buscaDirf(this.usuarioLogado)
      .then(dirf => {
        this.dataSourceCedulaC = dirf;
      })
      .catch(() => {
        this.snackBarService.error('Servidor não encontrado na base de dados')
      });
  }

  temPermissao(permissao: string) {
    return this.auth.temPermissao(permissao);
  }

  verificaIdades() {
    return this.recadastramentoService.verificarIdades(this.usuarioLogado)
    .then(status => {
      if(status) {
        this.flagMostrarMsgNaoRecadastrado = true;
        this.flagNaoMostrarMsgNaoRecadastrado = false;
      }else{
        this.flagMostrarMsgNaoRecadastrado = false;
        this.flagNaoMostrarMsgNaoRecadastrado = true;
      }
    });
  }

  verificaStatusRecadastramentoServidor() {
    return this.recadastramentoService.verificarIdades(this.servidorForm.value.cpfServidor)
    .then(status => {
      if(status) {
        return true;
      }else{
        return false;
      }
    });
  }


}
