import { Bairro } from "./Bairro";

export class Logradouro{
  cep : string;
  ativo : string;
  latitude : string;
  logradouro : string;
  logradouroSa : string;
  longitude : string;
  nomeLogradouro : string;
  nomeLogradouroSa : string;
  tipo : string;
  tipoSa : string;
  bairro = new Bairro();
}
