import { ComunicacaoService } from './../../../servicos_utilitarios/comunicacao.service';
import { catchError } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
//import {AuthService} from '../../../seguranca/auth.service';
//import {PerfilService} from '../../../screens/perfil/perfil.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/seguranca/auth.service';
import { ErrorHandlerService } from 'src/app/shared/error-handler.service';
import { RecadastramentoService } from 'src/app/pages/recadastramento/recadastramento.service';
import { DomSanitizer } from '@angular/platform-browser';
//import {ErrorHandlerService} from '../../../shared/error-handler.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidenavComponent implements OnInit {
  //public userImage= '../assets/img/users/user.jpg';
  //public userImage= '../assets/img/users/eu.jpg';
  /* public userImage = '../assets/img/users/user.png'; */
  userImage : any;

  fotoPerfil = false;

  public logoImage = '../assets/img/users/logo_05_v06.png';
  public menuItems: Array<any>;
  public settings: Settings;
  public url = '';

  usuarioLogado: string = '';
  cpfUsuarioLogado: string = '';

  constructor(
    //      public authService: AuthService,
    //      public perfilService: PerfilService,
    private recadastramentoService: RecadastramentoService,
    private comunicacaoService : ComunicacaoService,
    private sanitizer: DomSanitizer,
    public appSettings: AppSettings,
    public menuService: MenuService,
    private router: Router,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();

    // this.carregarusuarioPeloCodigo();
    const codigo = this.router.url;

    this.usuarioLogado = this.auth.jwtPayload?.nome;
    this.cpfUsuarioLogado = this.auth.jwtPayload?.user_name;

    this.carregarImagemPerfil();

    this.comunicacaoService.receberMensagem().subscribe(() => {
      this.carregarImagemPerfil();
    });

  }

  carregarImagemPerfil() {
    /* this.recadastramentoService.carregarFotoPerfil(this.cpfUsuarioLogado).subscribe((blob: Blob) => {
      const url = URL.createObjectURL(blob);
      this.userImage = this.sanitizer.bypassSecurityTrustUrl(url);
    }); */
    this.recadastramentoService.carregarFotoPerfil(this.cpfUsuarioLogado)
      .pipe(
        catchError(error => {
          this.userImage = '../assets/img/users/user.png';
          return null;
        })
      )
      .subscribe((blob: Blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            this.userImage = this.sanitizer.bypassSecurityTrustUrl(url);
          }
        });
  }

  logout() {
    this.auth.logout()
      .then(() => {

        const url = window.location.href;
        const separador = '/#/';

        const partes = url.split(separador);
        const primeiraParte = partes[0];

        window.location.replace(primeiraParte);

      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  /*
      carregarusuarioPeloCodigo(): void {
          this.perfilService
              .usuarioLogado(this.authService.jwtPayload?.cpf)
              .then((lista) => {
                  if (lista.foto != null) {
                      this.userImage = 'data:image/png;base64,' + lista.foto;
                  }
              })
              .catch((erro) => this.errorHandler.handle(erro));
      }
  */
  public closeSubMenus() {
    let menu = document.getElementById('vertical-menu');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}
