import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParentescoService {

  parentescoUrl: string;

  constructor(private http: HttpClient) {
    this.parentescoUrl = `${environment.apiUrl}/parentescos`;
  }

  buscarParentescos(){
    return this.http.get(`${this.parentescoUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
