import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authServive: AuthService,
    private router: Router
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(this.authServive.isAccessTokenInvalido()){
      return this.authServive.obterNovoAccessToken()
        .then(() => {
          if (this.authServive.isAccessTokenInvalido()) {
            this.router.navigate(['/login']);
            return false;
          }

          return true;
        });

    } else if(route.data['roles'] && !this.authServive.temQualquerPermissao(route.data['roles'])){
      this.router.navigate(['/nao-autorizado']);

      return false;
    }

    return true;

  }

  /* canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.authServive.isAccessTokenInvalido()) {

        return this.authServive.obterNovoAccessToken()
        .then(() => {
          if (this.authServive.isAccessTokenInvalido()) {
            this.router.navigate(['/login']);
            return false;
          }
          return true;
        });
        // Senao tiver nenhuma permissao retorne false
      } else if (next.data.roles && !this.authServive.temQualquerPermissao(next.data.roles)) {
        this.router.navigate(['/nao-autorizado']);
        return false;
      }
      return true;
  } */

}
