export const environment = {
  production: true,


  apiUrl: 'https://recadastramento01.segad.rr.gov.br:8443/servidorpublico-api',

  tokenWhitelistedDomains: [ new RegExp('https://recadastramento01.segad.rr.gov.br:8443/servidorpublico-api') ],

  tokenBlacklistedRoutes: [ new RegExp('\/oauth\/token')], //original ate 02/02/2023


  oauthCallbackUrl: 'https://recadastramento01.segad.rr.gov.br:8443/servidorpublico-api', // novo
  logoutRedirectToUrl: 'https://recadastramento01.segad.rr.gov.br:8443/servidorpublico-api', //novo
  tokenAllowedDomains: [ /recadastramento.rr.gov.br/ ] //novo


};
