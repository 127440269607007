import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContatosubtipoService {

  contatoSubTipoUrl: string;

  constructor(private http: HttpClient) {
    this.contatoSubTipoUrl = `${environment.apiUrl}/subtipocontatos`;
  }

  buscarSubTiposContatos(){
    return this.http.get(`${this.contatoSubTipoUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}

