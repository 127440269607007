//import { environment } from './../../environments/environment.prod';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CnhcategoriasService {

  categoriasCnhUrl: string;

  constructor(private http: HttpClient) {
    this.categoriasCnhUrl = `${environment.apiUrl}/categoriascnh`;
  }

  buscarCategoriasCnh(){
    return this.http.get(`${this.categoriasCnhUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
