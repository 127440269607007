import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCepService {

  constructor(private http: HttpClient) { }

  getCEP(cep: string){
    return this.http.get('https://viacep.com.br/ws/' + cep + '/json');
  }


  consultaCEPP(cep: string): Promise<any> {

    return this.http
      .get('https://viacep.com.br/ws/' + cep + '/json',  )
      .toPromise()
      .then((response) => {
        return response;
      });
  }

  consultaCEP(cep: string) {



    // Nova variável "cep" somente com dígitos.
    cep = cep.replace(/\D/g, '');

    // Verifica se campo cep possui valor informado.
    if (cep !== '') {
      // Expressão regular para validar o CEP.
      const validacep = /^[0-9]{8}$/;

      // Valida o formato do CEP.
      if (validacep.test(cep)) {
        return this.http.get(`//viacep.com.br/ws/${cep}/json`);
      }
    }


    return of({});
  }
}



/*
@Injectable()
export class AppService {

  AUTH_COOKIE: string = 'agendaCookie';
  constructor(private http: Http) { }

  getCEP(cep: string){
    return this.http.get('https://viacep.com.br/ws/'+ cep + '/json').map(a=> a.json());
  }
}
*/
