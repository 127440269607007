import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppSettings} from '../../app.settings';
import {Settings} from '../../app.settings.model';
import {ErrorHandlerService} from '../../shared/error-handler.service';
import {AuthService} from '../auth.service';
import { SnackBarService } from './../../servicos_utilitarios/snack-bar.service';
import { ReloadService } from 'src/app/servicos_utilitarios/reload-service.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

    public flagCount = false;;
    public form: FormGroup;
    public settings: Settings;
    public loginInvalid: boolean;
    public carregando: boolean;
    public cor = '#ffffff';
    public hide = true;

    constructor(
        public appSettings: AppSettings,
        public fb: FormBuilder,
        public router: Router,
        private auth: AuthService,
        private snackBarService: SnackBarService,
        private reloadService: ReloadService,
        private errorHandlerService: ErrorHandlerService,
        ) {
        this.settings = this.appSettings.settings;
        this.form = this.fb.group({
           // cpf    : [null, Validators.compose([Validators.required,])],
            matricula: [null, Validators.compose([Validators.required, Validators.minLength(9)])],
            password : [null, Validators.compose([Validators.required, Validators.minLength(5)])]
        });
    }

    ngOnInit() {
      /* this.reloadService.reload.subscribe(() => {
        // Execute a ação de recarregar a página aqui
        //location.reload();
      }) */

    }

    formataCPF(cpf): string {
        // retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, '');

        return cpf;
        // realizar a formatação...
        //return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').toString();
    }

    public onSubmit(values): void {
        if (this.form.valid) {
//      this.router.navigate(['/']);
            try {
                this.carregando = true;
                this.cor = '#283593';
                //const cpf = this.form.get('cpf').value;
                let matricula = this.form.get('matricula').value;
                matricula = this.formataCPF(matricula);
                const senha = this.form.get('password').value;
                this.auth.login(matricula, senha)
                    .then(() => {
                        this.carregando = false;
                        this.router.navigate(['/atualizacao-cadastral']);
                        // this.carregando = false;
                    })
                    .catch(erro => {
                        // this.loginInvalid = true;
                        this.carregando = false;
                        // this.carregandoButton = true;
                        this.cor = '#ffffff';
                        this.errorHandlerService.handle(erro);
                        //this.snackBarService.error(erro + '. Se for seu primeiro acesso, por favor, digite os 9 primeiros digitos do seu CPF')
                    });
            } catch (err) {
                // this.loginInvalid = true;
                this.carregando = false;
                this.cor = '#ffffff';
                // this.carregandoButton = true;
            }
        }
    }

    ngAfterViewInit() {
        this.settings.loadingSpinner = false;
    }
}
