import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dependente } from 'src/app/domain/Dependente';
import { Contato } from 'src/app/domain/Contato';
import { PessoaFisica } from 'src/app/domain/PessoaFisica';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecadastramentoService {

  servidorUrl: string;
  dependentesUrl: string;
  contatosUrl : string;

  constructor(private http: HttpClient) {
    //http://localhost:8099/servidorpublico-api
    this.servidorUrl = `${environment.apiUrl}/pessoasfisicas`;
    this.dependentesUrl = `${environment.apiUrl}/dependentes`;
    this.contatosUrl = `${environment.apiUrl}`;
  }

  enviarFotoPerfil(file: File, cpfTitular: string) {
      const formData = new FormData();
      formData.append('file', file);

      return this.http.post(`${this.servidorUrl}/${cpfTitular}/foto/upload`, formData)
          .toPromise()
          .then(( response : any) => {
            return response;
          })
  }


  enviarFotoPerfilDependente(file: File, cpfTitular: string) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.dependentesUrl}/${cpfTitular}/foto/upload`, formData)
        .toPromise()
        .then(( response : any) => {
          return "Foto enviada com sucesso";
        })
  }

  carregarFotoPerfil(cpf: string): Observable<Blob> {
    return this.http.get(`${this.servidorUrl}/${cpf}/foto/download`, { responseType: 'blob' });
  }

  cadastrarDadosIniciaisServidor(pessoa: PessoaFisica) {
    return this.http.post(`${this.servidorUrl}/cadastro`, pessoa)
    .toPromise()
    .then(( response : any) => {
      return response;
    })

  }

  cadastrarPessoaFisica (pessoa : PessoaFisica) {
    return this.http.post(`${this.servidorUrl}`, pessoa)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  buscarServidorPorCpf(cpf: string){
    return this.http.get(`${this.servidorUrl}/${cpf}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  buscarDependentesPf(cpf: string){
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.dependentesUrl}/pessoasfisicas?`, { params })
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  buscarDependentesPorCpf(cpfDependente: any){
    return this.http.get(`${this.dependentesUrl}/pessoasfisicas/${cpfDependente}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  verificarRecadastramento(cpf: string) {
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/verificar-recadastramento?`, { params })
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  verificarIdades(cpf: string) {
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/verificar-idades?`, { params })
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  buscarDependentesPorId(codDependente: number){

    return this.http.get(`${this.dependentesUrl}/${codDependente}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  buscarContatosPorId(codContato: number){

    return this.http.get(`${this.contatosUrl}/contatos/${codContato}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })

  }

  geraComprovanteRecadastramento(cpf: string){
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/download/comprovante?`, {params, responseType: 'blob' })
      .toPromise();
  }

  cadastrarDependente(dependente : Dependente) {
    return this.http.post(`${this.dependentesUrl}`, dependente)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  cadastrarContato(contato : Contato) {
    return this.http.post(`${this.contatosUrl}/contatos`, contato)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  removerDependente(codDependente : number): Promise<void>{
    return this.http.delete<void>(`${this.dependentesUrl}/${codDependente}`)
      .toPromise();
  }

  removerContato(codContato: number): Promise<void>{
    return this.http.delete<void>(`${this.contatosUrl}/contatos/${codContato}`)
      .toPromise();
  }

  atualizarDadosDependente(dependente : Dependente, codDependente : number ) {
    return this.http.put<Dependente>(`${this.dependentesUrl}/${codDependente}`, dependente)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  atualizarContato(contato : Contato, codContato : number ) {
    return this.http.put<Contato>(`${this.contatosUrl}/contatos/${codContato}`, contato)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  buscarContatosPf(cpf: string){
    let params = new HttpParams();
    params = params.set('cpf', cpf);

    return this.http.get(`${this.contatosUrl}/contatos/pessoasfisicas?`, { params })
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }


  /* funções próprias do tipo Date do Javascript.
    * A conversão da string no formato yyyy-MM-dd para Date pode ser realizada com a instanciação de um novo objeto
    * passando a string como parâmetro.
    */
  private converterStringsParaDatas(pessoas: PessoaFisica[]) {
    for (const pessoa of pessoas) {

      //Evita bug na hora da edição, adiciona o timezone do usuário
      let offset = new Date().getTimezoneOffset() * 60000;

      pessoa.datNascimento = new Date(new Date(pessoa.datNascimento).getTime() + offset);

      if (pessoa.datNascimento) {
        pessoa.datNascimento = new Date(new Date(pessoa.datNascimento).getTime() + offset);
      }
    }
  }
}
