//import { environment } from './../../environments/environment.prod';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TiposanguineoService {

  tiposSanguineosUrl: string;

  constructor(private http: HttpClient) {
    this.tiposSanguineosUrl = `${environment.apiUrl}/tipos`;
  }

  buscarTiposSanguineos(){
    return this.http.get(`${this.tiposSanguineosUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
