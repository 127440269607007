import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeficienciaService {

  deficienciasUrl: string;

  constructor(private http: HttpClient) {
    this.deficienciasUrl = `${environment.apiUrl}/deficiencias`;
  }

  buscarDeficiencias(){
    return this.http.get(`${this.deficienciasUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

}
