import { MatPaginatorIntl } from '@angular/material/paginator';

const escalaLabel = (pagina: number, tamanhoPagina: number, tamanhoTotal: number) => {
    if (tamanhoTotal == 0 || tamanhoPagina == 0) { return `0 de ${tamanhoTotal}`; }

    length = Math.max(tamanhoTotal, 0);

    const inicialIndex = pagina * tamanhoPagina;

    // Se o índice inicial exceder o tamanho da lista, não tente corrigir o índice final até o final.
    const finalIndex = inicialIndex < tamanhoTotal
        ? Math.min(inicialIndex + tamanhoPagina, tamanhoTotal)
        : inicialIndex + tamanhoPagina;

    return `${inicialIndex + 1} - ${finalIndex} de ${tamanhoTotal}`;
}


// tslint:disable-next-line: typedef
export function PaginacaoIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    paginatorIntl.nextPageLabel = 'Próxima página';
    paginatorIntl.previousPageLabel = 'Página anterior';
    paginatorIntl.getRangeLabel = escalaLabel;

    return paginatorIntl;
}
