import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) { }

  sucess(mensagem: string ){
    this._snackBar.open(mensagem, '', {duration:2*1000, panelClass:''})
  }

  error(mensagem: string ){
    this._snackBar.open(mensagem, '', {duration:10*1000, panelClass:''})
  }

  open(mensagem: string ){
    this._snackBar.open(mensagem, '', {duration:15*1000, panelClass:''})
  }

}
