import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {

  estadoUrl: string;

  constructor(private http: HttpClient) {
    this.estadoUrl = `${environment.apiUrl}/estados`;
  }

  buscarEstados(){
    return this.http.get(`${this.estadoUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }
}
