import { Deficiencia } from "./Deficiencia";
import { Parentesco } from "./Parentesco";
import { PessoaFisica } from "./PessoaFisica";
import { Sexo } from "./Sexo";

export class Dependente {

  codDependente : number;
  cpfDependente : string;
  codParentesco = new Parentesco();
  codSexo = new Sexo();
  cpfServidor = new PessoaFisica();
  datNascimento : Date;
  nomDependente : string;
  obsNecessidadeEspecial : string;
  depIrrf : Boolean;
  depPrevidenciario : Boolean;
  depUniversitario : Boolean;
  codIncapacidadeFisicaMental : Boolean;
  deficiencia = new Deficiencia();


}
