import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContraChequeService {

  servidorUrl: string;

  constructor(private http: HttpClient) {
    this.servidorUrl = `${environment.apiUrl}`;
  }

  buscaDadosContraChequePf(cnpj: string, cpf: string){
    let params = new HttpParams();
    params = params.set('cnpj', cnpj);
    params = params.set('cpf', cpf);

    return this.http.get(`${this.servidorUrl}/financeiro/financeiro-all?`, { params })
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

  geraContraChequePf(cnpj: string, cpf: string, matricula: string, periodo_financeiro: string, cod_folha: number) {
    let params = new HttpParams();
    params = params.set('cnpj', cnpj);
    params = params.set('cpf', cpf);
    params = params.set('matricula', matricula);
    params = params.set('periodoInicial', periodo_financeiro);
    params = params.set('periodoFinal', periodo_financeiro);
    params = params.set('folha', cod_folha);

    return this.http.get(`${this.servidorUrl}/financeiro/contracheque?`, {params, responseType: 'blob' })
      .toPromise();

  }
}
