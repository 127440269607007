import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-atualizacao-recadastramento',
  templateUrl: './atualizacao-recadastramento.component.html',
  styleUrls: ['./atualizacao-recadastramento.component.scss']
})
export class AtualizacaoRecadastramentoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AtualizacaoRecadastramentoComponent>,
  ) { }

  ngOnInit(): void {
  }


  clickBotaoCiente() {
    this.dialogRef.close();
  }

}
