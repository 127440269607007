export class Bairro{

  codBairro: string;
	bairroSa: string;
	latitude: string;
	longitude: string;
  nomeBairro: string


}
