import { CnhCategoria } from './../../domain/CnhCategoria';
import { IdentificacaoOrgao } from './../../domain/IdentificacaoOrgao';
import { ParentescoService } from './../../servicos_utilitarios/parentesco.service';
import { IdentificacaoOrgaoService } from './../../servicos_utilitarios/identificacao-orgao.service';
import { NacionalidadeService } from './../../servicos_utilitarios/nacionalidade.service';
import { EscolaridadeService } from './../../servicos_utilitarios/escolaridade.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RecadastramentoService } from './recadastramento.service';
import { PessoaFisica } from 'src/app/domain/PessoaFisica';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/seguranca/auth.service';
import { SexoService } from 'src/app/servicos_utilitarios/sexo.service';
import { EstadocivilService } from 'src/app/servicos_utilitarios/estadocivil.service';
import { RacaCorService } from 'src/app/servicos_utilitarios/raca-cor.service';
import { CidadeService } from 'src/app/servicos_utilitarios/cidade.service';
import { EstadoService } from 'src/app/servicos_utilitarios/estado.service';
import { IdentificacaoTipoService } from 'src/app/servicos_utilitarios/identificacao-tipo.service';
import { Contato } from 'src/app/domain/Contato';
import { Dependente } from 'src/app/domain/Dependente';
import { Parentesco } from 'src/app/domain/Parentesco';
import { Sexo } from 'src/app/domain/Sexo';
import { ContatosubtipoService } from 'src/app/servicos_utilitarios/contatosubtipo.service';
import { ContatoSubTipo } from 'src/app/domain/ContatoSubTipo';
import { ContatoTipo } from 'src/app/domain/ContatoTipo';
import { Estado } from 'src/app/domain/Estado';
import { EstadoCivil } from 'src/app/domain/EstadoCivil';
import { GrauInstrucao } from 'src/app/domain/GrauInstrucao';
import { IdentificacaoTipo } from 'src/app/domain/IdentificacaoTipo';
import { Nacionalidade } from 'src/app/domain/Nacionalidade';
import { Cidade } from 'src/app/domain/Cidade';
import { RacaCor } from 'src/app/domain/RacaCor';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from '../ui/dialog/dialog.component';
import { UsuarioAuxService } from 'src/app/servicos_utilitarios/usuario-aux.service';
import { TiposanguineoService } from 'src/app/servicos_utilitarios/tiposanguineo.service';
import { TipoSanguineo } from 'src/app/domain/TipoSanguineo';
import { CnhcategoriasService } from 'src/app/servicos_utilitarios/cnhcategorias.service';
import { Logradouro } from 'src/app/domain/Logradouro';
import { DeficienciaService } from 'src/app/servicos_utilitarios/deficiencia.service';
import { Deficiencia } from 'src/app/domain/Deficiencia';
import { UsuarioRole } from 'src/app/domain/UsuarioRole';
import { ErrorHandlerService } from 'src/app/shared/error-handler.service';
import { SnackBarService } from 'src/app/servicos_utilitarios/snack-bar.service';
import { ResetSenhaFormComponent } from 'src/app/senhas/reset-senha-form/reset-senha-form.component';
import { Validacoes } from 'src/app/util/Validacoes';
import { Utils } from 'src/app/util/Utils';
import { Router } from '@angular/router';
import { AtualizacaoRecadastramentoComponent } from 'src/app/mensagem/atualizacao-recadastramento/atualizacao-recadastramento.component';
import { PessoaJuridica } from 'src/app/domain/PessoaJuridica';
import { ComunicacaoService } from 'src/app/servicos_utilitarios/comunicacao.service';




export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export interface dados {
  nomDependente: string;
  cpfDependente: string;
  codParentesco: string;
}


export interface dadosContato {
  nomeContato: string;
  nomeContatoSubTipo: string;
  //cpf: string;
}

@Component({
  selector: 'app-recadastramento',
  templateUrl: './recadastramento.component.html',
  styleUrls: ['./recadastramento.component.scss'],
  //standalone: true,
  //imports: [MatFormFieldModule, MatInputModule, MatSelectModule],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]

})

export class RecadastramentoComponent implements OnInit {


  /* Instanciação de objetos */

  pessoaFisica = new PessoaFisica();
  pessoaFisicaContato = new PessoaFisica();
  pessoaFisicaDependente = new PessoaFisica()
  depPessoaFisica = new PessoaFisica();
  contato = new Contato();
  contatoTipo = new ContatoTipo()
  contatoSubTipo = new ContatoSubTipo();
  dependente = new Dependente();
  sexo = new Sexo();
  sexoDependente = new Sexo();
  sexoServidor = new Sexo();
  parentesco = new Parentesco();
  estadoCtps = new Estado();
  estadoIdentificacaoUf = new Estado();
  estadoNaturalidadeUf = new Estado();
  estadoCnhUf = new Estado();
  estadoReservistaUf = new Estado();
  estadoTituloUf = new Estado();
  logradouro = new Logradouro();
  cargaLogradouro = new Logradouro();
  estadoCivil = new EstadoCivil();
  grauInstrucao = new GrauInstrucao();
  identificacaoOrgao = new IdentificacaoOrgao();
  identificacaoTipo = new IdentificacaoTipo();
  nacionalidade = new Nacionalidade();
  //cidadeNaturalidade = new Cidade();
  cidadeTitulo = new Cidade();
  racaCor = new RacaCor();
  tipoSanguineo = new TipoSanguineo();
  cnhCategoria = new CnhCategoria();
  deficiencia = new Deficiencia();
  usuarioRole = new Array<UsuarioRole>;
  usuarioRoleContatos = new Array<UsuarioRole>;
  usuarioRoleDependentes = new Array<UsuarioRole>;
  pessoaJuridica = new Array<PessoaJuridica>;


  codDependente: number;
  codContato: number

  dataArray: any;
  flagMessage = false;

  recadastramentoConcluido: boolean;

  fileName = '';

  editandoDependente = false;
  cadastrandoDependente = true;

  editandoContato = false;
  cadastrandoContato = true;

  desabilitarBotao = false;

  mostrarBotaoProximo = false;
  mostrarBotaoGravar = true;


  registrationFormPessoal: FormGroup;
  registrationFormDependenteDetails: FormGroup;
  registrationFormContatoDetails: FormGroup;

  public registrationForm: FormGroup;
  usuarioLogado: string = '';
  cep: string = '';

  file: File | null = null;


  title = 'Angular Material Stepper Example with single '
    + 'Reactive form across mulitple child components';

  searchField: string;
  clearSearchField() {
    this.searchField = '';
  }

  dadosDependentes: dados[] = [];

  dadosContatos: dadosContato[] = [];

  displayedColumnsContact: string[] = ['nomeContato', 'nomeContatoSubTipo', 'acoes'];
  dataSourceContact = new MatTableDataSource<dadosContato>(this.dadosContatos);

  displayedColumns: string[] = ['nomDependente', 'cpfDependente', 'codParentesco', 'acoes'];
  dataSource = new MatTableDataSource<dados>(this.dadosDependentes);

  //public cpfMask = [/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  // public pisPasepMask = [/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/];
  //public cepMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  // public telMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  // public cnpjMask = [/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public salutations = [
    { id: 1, name: 'Mr' },
    { id: 2, name: 'Mrs' }
  ];
  public genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' }
  ];
  public countries = [
    { id: 1, name: 'USA' },
    { id: 2, name: 'Canada' },
    { id: 3, name: 'Mexico' },
    { id: 4, name: 'UK' },
    { id: 5, name: 'France' },
    { id: 6, name: 'Italy' }
  ];
  public states = [
    { id: 1, name: 'Arkansas' },
    { id: 2, name: 'Texas' },
    { id: 3, name: 'California' },
    { id: 4, name: 'Florida' },
    { id: 5, name: 'Other' }
  ];

  //Basic select
  selectedSexo: number;
  public sexos = [];

  selectedDeficiencias: number;
  public deficiencias = [];

  selectedTiposSanguineos: number;
  public tiposSanguineos = [];

  selectedCategoriasCnh: number;
  public categoriasCnh = [];

  selectedSubTipo: number;
  public subTipos = [];

  selectedDepSexo: number;
  public depSexos = [];

  selectedEstadoCivil: number;
  public estadosCivis = [];

  selectedTipoIdentificacao: number;
  public tiposIdentificacao = [];

  selectedIdentificacaoOrgao: number;
  public OrgaosExpeditores = [];
  //selectedIdentificacaoOrgao = this.OrgaosExpeditores[99].value;


  selectedCidade: number;
  public cidades = [];

  //selectedCidadeTitulo: number;
  //public cidadesTitulo = [];

  selectedEstado: string;
  public estados = [];

  selectedEstadoNaturalidade: string;
  public estadosNaturalidade = [];

  //selectedCidadeNaturalidade: number;
  //public cidadeNaturalidade = [];

  selectedEstadoEmissorCtps: string;
  public estadosEmissorCtps = [];

  selectedEstadoEmissorCnh: string;
  public estadosEmissorCnh = [];

  selectedEstadoEmissorReservista: string;
  public estadosEmissorReservista = [];

  selectedEstadoEmissorIdentificacao: string;
  public estadosEmissorIdentificacao = [];

  selectedEstadoEmissorTitulo: string;
  public estadosEmissorTitulo = [];

  selectedRacaCor: number;
  public racasCores = [];

  selectedDepGrauParentesco: number;
  public grausParentescos = [];


  selectedNacionalidade: number;
  public nacionalidades = [];

  selectedGrauInstrucao: number;
  public grausInstrucao = [];

  selectedIdentificacaoTipo: number;
  selectedEstadoIdentificacaoUf: string;

  //selectedEscolaridade: number;
  //public escolaridade = [];

  constructor(
    private formBuilder: FormBuilder,
    private recadastramentoService: RecadastramentoService,
    private auth: AuthService,
    private router: Router,
    private sexoService: SexoService,
    private estadocivilService: EstadocivilService,
    private racaCorService: RacaCorService,
    private escolaridadeService: EscolaridadeService,
    private nacionalidadeService: NacionalidadeService,
    private cidadeService: CidadeService,
    private contatosubtipoService: ContatosubtipoService,
    private estadoService: EstadoService,
    private parentescoService: ParentescoService,
    private comunicacaoService : ComunicacaoService,
    private identificacaoTipoService: IdentificacaoTipoService,
    private identificacaoOrgaoService: IdentificacaoOrgaoService,
    private usuarioAuxService: UsuarioAuxService,
    private deficienciaService: DeficienciaService,
    private tiposanguineoService: TiposanguineoService,
    private cnhcategoriasService: CnhcategoriasService,
    private snackBarService: SnackBarService,
    private errorHandlerService: ErrorHandlerService,
    public dialog: MatDialog
  ) { }


  ngOnInit() {

    this.carregarSexos();
    this.carregarEstadoCivil();
    this.carregarRacaCor();
    this.carregarGrauInstrucao();
    this.carregarNacionalidade();
    this.carregarCidades();
    this.carregarEstados();
    this.carregarIdentificacaoTipo();
    this.carregarIdentificacaoOrgao();
    this.carregarParentesco();
    this.carregarSubTipoContatos();
    this.carregarTiposSanguineos();
    this.carregarCategoriasCnh();
    this.carregarDeficiencias();

    this.usuarioLogado = this.auth.jwtPayload?.user_name;

    if (this.usuarioLogado) {
      this.carregarServidor(this.usuarioLogado);
      this.carregarContatosPf(this.usuarioLogado);
      this.carregarDependentes(this.usuarioLogado)
      this.verificaUpdateSenha(this.usuarioLogado);
      this.verificaRecadastramento();
    }



    this.registrationFormPessoal = this.formBuilder.group({
      nome: [null, Validators.required],
      cpf: [null, Validators.required],
      nis: [null, Validators.compose([Validators.required, Validacoes.validaPIS])],
      datNascimento: [null, Validators.compose([Validators.required, Validacoes.getAge, Validacoes.verificaIdade])],
      sexo: [null, Validators.required],
      logradouroSa: [null, Validators.required],
      endNumero: [null, Validators.required],
      endComplemento: [null, Validators.required],
      bairroSa: [null, Validators.required],
      ctpsNumero: [null, null],
      ctpsSerie: [null, null],
      estadoCtpsUf: [null, null],
      ctpsDataEmissao: [null, null],
      identificacaoTipo: [null, Validators.required],
      ideNumero: [null, Validators.required],
      identificaoOrgao: [null, Validators.required],
      estadoIdentificacaoUf: [null, Validators.required],
      ideDataEmissao: [null, Validators.compose([Validators.required, Validacoes.validaDataExpedicao])],
      ideDataValidade: [null, null],
      estadoCivil: [null, Validators.required],
      racaCor: [null, Validators.required],
      maeNome: [null, Validators.required],
      paiNome: [null, null],
      nacionalidade: [null, Validators.required],
      datChegadaBrasil: [null, null],
      datNaturalizacao: [null, null],
      datLimitePermanencia: [null, null],
      registroNacionalEstrangeiro: [null, null],
      nomNaturalidadeCidade: [null, Validators.required],
      estadoNaturalidadeUf: [null, Validators.required],
      grauInstrucao: [null, Validators.required],
      codResidenciaPropria: [null, null],
      codResidenciaPropriaFgts: [null, null],
      codResidenteExterior: [null, null],
      codCasadoComBrasileiro: [null, null],
      anoFimPrimeiroEmprego: [null, null],
      primeiroEmpregoAno: [null, Validators.required],
      //cnhNumero: [null, Validacoes.validaCNH],
      cnhNumero: [null, null],
      cnhValidade: [null, null],
      cnhDataExpedicao: [null, null],
      cnhDataPrimeiraHabilitacao: [null, null],
      cnhExpedicaoUf: [null, null],
      cnhCategoria: [null, null],
      reservistaNumero: [null],
      reservistaOrgao: [null],
      reservistaCategoria: [null],
      reservistaAnoReserva: [null],
      reservistaDataExpedicao: [null],
      reservistaExpedicaoUf: [null],
      tituloEleitorNumero: [null, Validators.compose([Validators.required, Validacoes.validaTituloEleitor])],
      tituloDataEmissao: [null, Validators.compose([Validators.required, Validacoes.validaDataExpedicao])],
      tituloSecao: [null, Validators.required],
      tituloZona: [null, Validators.required],
      nomTituloCidade: [null, Validators.required],
      codTituloUf: [null, Validators.required],
      codFilhoBrasileiro: [null, null],
      codDeficienciaFisica: [null, null],
      codDeficienciaVisual: [null, null],
      codDeficienciaAuditiva: [null, null],
      codDeficienciaMental: [null, null],
      codDeficienciaIntelectual: [null, null],
      codDeficienciaReabilitado: [null, null],
      codDeficienciaCota: [null, null],
      codTipoSanguineo: [null, null],
      endCep: [null, Validators.required],
    }),

    this.registrationFormPessoal.get('sexo').valueChanges
      .subscribe(value => {
          if (value == 1) {
            this.registrationFormPessoal.get('reservistaNumero').setValidators(Validators.required);
            this.registrationFormPessoal.get('reservistaOrgao').setValidators(Validators.required);
            this.registrationFormPessoal.get('reservistaCategoria').setValidators(Validators.required);
            this.registrationFormPessoal.get('reservistaAnoReserva').setValidators(Validators.required);
            this.registrationFormPessoal.get('reservistaDataExpedicao').setValidators(Validators.required);
            this.registrationFormPessoal.get('reservistaExpedicaoUf').setValidators(Validators.required);
          } else {
            this.registrationFormPessoal.get('reservistaNumero').setValidators(null);
            this.registrationFormPessoal.get('reservistaOrgao').setValidators(null);
            this.registrationFormPessoal.get('reservistaCategoria').setValidators(null);
            this.registrationFormPessoal.get('reservistaAnoReserva').setValidators(null);
            this.registrationFormPessoal.get('reservistaDataExpedicao').setValidators(null);
            this.registrationFormPessoal.get('reservistaExpedicaoUf').setValidators(null);
          }

          this.registrationFormPessoal.get('reservistaNumero').updateValueAndValidity();
          this.registrationFormPessoal.get('reservistaOrgao').updateValueAndValidity();
          this.registrationFormPessoal.get('reservistaCategoria').updateValueAndValidity();
          this.registrationFormPessoal.get('reservistaAnoReserva').updateValueAndValidity();
          this.registrationFormPessoal.get('reservistaDataExpedicao').updateValueAndValidity();
          this.registrationFormPessoal.get('reservistaExpedicaoUf').updateValueAndValidity();

    }),
    this.registrationFormDependenteDetails = this.formBuilder.group({
      cpfDependente: [null, Validators.compose([Validators.required, Validacoes.validaCpf])],
      nomDependente: [null, Validators.required],
      datNascimento: [null, Validators.compose([Validators.required, Validacoes.getAgeDependente, Validacoes.verificaIdade])],
      codSexo: [null, Validators.required],
      deficiencia: [null, Validators.required],
      codParentesco: [null, Validators.required],
      depIrrf: [null, null],
      depPrevidenciario: [null, null],
      depUniversitario: [null, null],
      codIncapacidadeFisicaMental: [null, null],
      obsNecessidadeEspecial: [null, null]
    }),

      this.registrationFormContatoDetails = this.formBuilder.group({
        nomeContato: [null, Validators.required],
        contatoTipo: [null, Validators.required],
        //pessoaFisica: [null, null],
      })

  }

  // Propriedades do formulário que vamos utilizar para obter os erros

  getErrorMessageCpf() {
    if (this.cpf.hasError('required')) {
      return 'Você deve inserir um valor';
    }
  }

  getErrorMessageNis() {
    if (this.nis.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.nis.hasError('pisInvalido')) {
      return 'Digite um pisPasep válido';
    }
  }

  getErrorMessageDatNascimento() {
    if (this.datNascimento.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.datNascimento.hasError('idadeInvalida')) {
      return 'Data de nascimento inválida';
    }
  }

 /*  getErrorMessageCnh() {
    if (this.cnhNumero.hasError('invalidaCnh')) {
      return 'Número de cnh inválido';
    }
  } */

  getErrorMessageTituloEleitorNumero() {
    if (this.tituloEleitorNumero.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.tituloEleitorNumero.hasError('tituloInvalido')) {
      return 'Título inválido';
    }
  }

  getErrorMessageTituloDataEmissao() {
    if (this.tituloDataEmissao.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.tituloDataEmissao.hasError('dataInvalida')) {
      return 'Data emissão do título inválida';
    }
  }

  getErrorMessageIdeDataEmissao() {
    if (this.ideDataEmissao.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.ideDataEmissao.hasError('dataInvalida')) {
      return 'Data emissão inválida';
    }
  }

  getErrorMessageDatNascimentoDependente() {
    if (this.datNascimentoDependente.hasError('required')) {
      return 'Você deve inserir um valor';
    }

    if (this.datNascimentoDependente.hasError('dataInvalida')) {
      return 'Data de nascimento inválida';
    }
  }


  get cpf() {
    return this.registrationFormPessoal.get('cpf');
  }

  get cpfDependente() {
    return this.registrationFormDependenteDetails.get('cpfDependente');
  }

  get nis() {
    return this.registrationFormPessoal.get('nis');
  }

  get datNascimento() {
    return this.registrationFormPessoal.get('datNascimento');
  }

  get cnhNumero() {
    return this.registrationFormPessoal.get('cnhNumero');
  }

  get tituloEleitorNumero() {
    return this.registrationFormPessoal.get('tituloEleitorNumero');
  }

  get datNascimentoDependente() {
    return this.registrationFormDependenteDetails.get('datNascimento');
  }

  get ideDataEmissao() {
    return this.registrationFormPessoal.get('ideDataEmissao');
  }

  get tituloDataEmissao() {
    return this.registrationFormPessoal.get('tituloDataEmissao');
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }

  carregarServidor(cpf: string) {
    this.recadastramentoService.buscarServidorPorCpf(cpf)
      .then(pessoa => {

        this.pessoaFisica = pessoa;

        if(this.pessoaFisica.sexo == null){
          this.selectedSexo = 99;
        }else{
          this.selectedSexo = this.pessoaFisica.sexo.codSexo;
        }

        if(this.pessoaFisica.estadoCivil == null){
          this.selectedEstadoCivil = 99;
        }else{
          this.selectedEstadoCivil = this.pessoaFisica.estadoCivil.codEstadoCivil;
        }

        if(this.pessoaFisica.racaCor == null){
          this.selectedRacaCor = 99;
        }else{
          this.selectedRacaCor = this.pessoaFisica.racaCor.codRacaCor;
        }

        if(this.pessoaFisica.grauInstrucao == null){
          this.selectedGrauInstrucao = 99;
        }else{
          this.selectedGrauInstrucao = this.pessoaFisica.grauInstrucao.codGrauInstrucao;
        }

        if(this.pessoaFisica.estadoNaturalidadeUf == null){
          this.selectedEstadoNaturalidade = "NA";
        }else{
          this.selectedEstadoNaturalidade = this.pessoaFisica.estadoNaturalidadeUf.codEstado;
        }

        if(this.pessoaFisica.nacionalidade == null){
          this.selectedNacionalidade = 999;
        }else{
          this.selectedNacionalidade = this.pessoaFisica.nacionalidade.codNacionalidade;
        }

        if(this.pessoaFisica.estadoCtpsUf == null){
          this.selectedEstadoEmissorCtps = "NA";
        }else{
          this.selectedEstadoEmissorCtps = this.pessoaFisica.estadoCtpsUf.codEstado;
        }

        if(this.pessoaFisica.identificacaoTipo == null){
          this.selectedIdentificacaoTipo = 99;
        }else{
          this.selectedIdentificacaoTipo = this.pessoaFisica.identificacaoTipo.codIdentificaoTipo;
        }

        if(this.pessoaFisica.identificaoOrgao == null){

          this.selectedIdentificacaoOrgao = 99;
        }else{
          this.selectedIdentificacaoOrgao = this.pessoaFisica.identificaoOrgao.codIdentificacaoOrgao;
        }

        if(this.pessoaFisica.estadoIdentificacaoUf == null){
          this.selectedEstadoIdentificacaoUf = "NA";
        }else{
          this.selectedEstadoIdentificacaoUf = this.pessoaFisica.estadoIdentificacaoUf.codEstado;
        }

        if(this.pessoaFisica.reservistaExpedicaoUf == null){
          this.selectedEstadoEmissorReservista = "NA";
        }else{
          this.selectedEstadoEmissorReservista = this.pessoaFisica.reservistaExpedicaoUf.codEstado;
        }

        if(this.pessoaFisica.cnhCategoria == null){

          this.selectedCategoriasCnh = 99;
        }else{
          this.selectedCategoriasCnh = this.pessoaFisica.cnhCategoria.codCnhCategoria;
        }

        if(this.pessoaFisica.cnhExpedicaoUf == null){
          this.selectedEstadoEmissorCnh = "NA";
        }else{
          this.selectedEstadoEmissorCnh = this.pessoaFisica.cnhExpedicaoUf.codEstado;
        }

        if(this.pessoaFisica.codTipoSanguineo == null){
          this.selectedTiposSanguineos = 99;
        }else{
          this.selectedTiposSanguineos = this.pessoaFisica.codTipoSanguineo.codTipoSanguineo;
        }

        if(this.pessoaFisica.codTituloUf == null){
          this.selectedEstadoEmissorTitulo = "NA";
        }else{
          this.selectedEstadoEmissorTitulo = this.pessoaFisica.codTituloUf.codEstado;
        }

        this.usuarioRole = this.pessoaFisica.usuarioRoles;
        this.pessoaJuridica = this.pessoaFisica.pessoaJuridica;

        this.pessoaFisicaContato = pessoa;
        this.usuarioRoleContatos = this.pessoaFisicaContato.usuarioRoles;

        this.pessoaFisicaDependente = pessoa;
        this.usuarioRoleDependentes = this.pessoaFisicaDependente.usuarioRoles;

      })
  }

  carregarCategoriasCnh() {
    this.cnhcategoriasService.buscarCategoriasCnh()
      .then(categorias => {
        this.categoriasCnh = categorias.map((cat: any) => ({ value: cat.codCnhCategoria, viewValue: cat.nomeCnhCategoria }));
      })
  }

  carregarTiposSanguineos() {
    this.tiposanguineoService.buscarTiposSanguineos()
      .then(tipos => {
        this.tiposSanguineos = tipos.map((tipo: any) => ({ value: tipo.codTipoSanguineo, viewValue: tipo.nomeTipoSanguineo }));
      })
  }

  carregarSexos() {
    this.sexoService.buscarSexo()
      .then(listaSexos => {
        this.depSexos = listaSexos.map((s: any) => ({ value: s.codSexo, viewValue: s.nomeSexo }));
        this.sexos = listaSexos.map((sexo: any) => ({ value: sexo.codSexo, viewValue: sexo.nomeSexo }));
      })
  }


  carregarDeficiencias() {
    this.deficienciaService.buscarDeficiencias()
      .then(deficiencias => {
        this.deficiencias = deficiencias.map((def: any) => ({ value: def.codDeficiencia, viewValue: def.nomDeficiencia }));
      })
  }

  carregarSubTipoContatos() {
    this.contatosubtipoService.buscarSubTiposContatos()
      .then(listaSubTiposContatos => {
        this.subTipos = listaSubTiposContatos.map((contatoSub: any) => ({ value: contatoSub.codContatoSubTipo, viewValue: contatoSub.nomeContatoSubTipo }));
      })
  }
  carregarEstadoCivil() {
    this.estadocivilService.buscarEstadoCivil()
      .then(listaEstadosCivis => {
        this.estadosCivis = listaEstadosCivis.map((es: any) => ({
          value: es.codEstadoCivil,
          viewValue: es.nomeEstadoCivil
        }));
      })
  }

  carregarRacaCor() {
    this.racaCorService.buscarRacaCor()
      .then(listaRacaCor => {
        this.racasCores = listaRacaCor.map((rc: any) => ({
          value: rc.codRacaCor,
          viewValue: rc.nomRacaCor
        }));
      })
  }

  carregarGrauInstrucao() {
    this.escolaridadeService.buscarEscolaridade()
      .then(listaEscolaridade => {
        this.grausInstrucao = listaEscolaridade.map((gi: any) => ({
          value: gi.codGrauInstrucao,
          viewValue: gi.nomeGrauInstrucao
        }));
      })
  }

  carregarNacionalidade() {
    this.nacionalidadeService.buscarNacionalidade()
      .then(listaNacionalidades => {
        this.nacionalidades = listaNacionalidades.map((nac: any) => ({
          value: nac.codNacionalidade,
          viewValue: nac.nomeNacionalidade
        }));
      })
  }

  carregarCidades() {
    this.cidadeService.buscarCidades()
      .then(listaCidades => {
        this.cidades = listaCidades.map((cidade: any) => ({
          value: cidade.codCidade,
          viewValue: cidade.nomeCidade
        }));

        //this.cidadesTitulo = listaCidades.map((cidadeTitulo: any) => ({ value: cidadeTitulo.codCidade,
        //  viewValue: cidadeTitulo.nomeCidade }));
      })
  }

  carregarEstados() {
    this.estadoService.buscarEstados()
      .then(listaEstados => {
        this.estados = listaEstados.map((estado: any) => ({
          value: estado.codEstado,
          viewValue: estado.codEstado
        }));
        this.estadosNaturalidade = listaEstados.map((estadoNaturalidade: any) => ({
          value: estadoNaturalidade.codEstado,
          viewValue: estadoNaturalidade.codEstado
        }));
        this.estadosEmissorCtps = listaEstados.map((estadoctps: any) => ({
          value: estadoctps.codEstado,
          viewValue: estadoctps.codEstado
        }));
        this.estadosEmissorCnh = listaEstados.map((estadocnh: any) => ({
          value: estadocnh.codEstado,
          viewValue: estadocnh.codEstado
        }));
        this.estadosEmissorReservista = listaEstados.map((estadores: any) => ({
          value: estadores.codEstado,
          viewValue: estadores.codEstado
        }));

        this.estadosEmissorIdentificacao = listaEstados.map((estadoIde: any) => ({
          value: estadoIde.codEstado,
          viewValue: estadoIde.codEstado
        }));

        this.estadosEmissorTitulo = listaEstados.map((estadoTitulo: any) => ({
          value: estadoTitulo.codEstado,
          viewValue: estadoTitulo.codEstado
        }));
      })
  }

  carregarIdentificacaoTipo() {
    this.identificacaoTipoService.buscarIdentificacaoTipos()
      .then(listaIdentificacaoTipos => {
        this.tiposIdentificacao = listaIdentificacaoTipos.map((tipos: any) => ({
          value: tipos.codIdentificaoTipo,
          viewValue: tipos.nomeIdentificacaoTipo
        }));
      })
  }

  carregarIdentificacaoOrgao() {
    this.identificacaoOrgaoService.buscarIdentificacaoOrgao()
      .then(listaIdentificacaoOrgao => {
        this.OrgaosExpeditores = listaIdentificacaoOrgao.map((orgao: any) => ({
          value: orgao.codIdentificacaoOrgao,
          viewValue: orgao.nomIdentificaoOrgao
        }));
      })
  }

  carregarParentesco() {
    this.parentescoService.buscarParentescos()
      .then(parentesco => {
        this.grausParentescos = parentesco.map((parentesco: any) => ({
          value: parentesco.codParentesco,
          viewValue: parentesco.nomParentesco
        }));
      })
  }

  carregarContatosPf(cpf: string) {

    this.recadastramentoService.buscarContatosPf(cpf)
      .then(listaContatos => {
        this.dataSourceContact = listaContatos;
      })

  }

  carregarDependentes(cpf: string) {
    this.recadastramentoService.buscarDependentesPf(cpf)
      .then(listaDependentes => {
        this.dataSource = listaDependentes;
      })
  }

  cadastrarServidor() {

    if (this.file) {
      this.recadastramentoService.enviarFotoPerfil(this.file, this.usuarioLogado)
      .then( response => {})
      .catch(erro => {
        this.comunicacaoService.emitirMensagem();
      })
    }

    if (this.registrationFormPessoal.valid) {
      this.constroiObjetoPessoaFisica();
      this.recadastramentoService.cadastrarPessoaFisica(this.pessoaFisica)
        .then(() => {
          this.desabilitarBotao = true;
          this.mostrarBotaoProximo = true;
          this.mostrarBotaoGravar = false;
          this.snackBarService.sucess('Dados pessoais cadastrado com sucesso!');
          setTimeout(() => {
            this.desabilitarBotao = false;
          }, 10000)
          /* this.router.navigate(['/comprovante-recadastramento']); */
        })
        .catch(erro => {
          this.desabilitarBotao = true;
          this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos de dados pessoais estão preenchidos corretamente e tente novamente')
          //this.errorHandlerService.handle(erro);
          setTimeout(() => {
            this.desabilitarBotao = false;
          }, 10000)
        })
    } else {
      this.snackBarService.error('Formulario inválido! Preencha os campos corretamente e tente novamente');
    }

  }
  adicionarDependente() {

    if (this.registrationFormDependenteDetails.valid) {
      this.dependente = this.registrationFormDependenteDetails.value;
      this.dependente.cpfDependente = Utils.formataStrings(this.registrationFormDependenteDetails.value.cpfDependente);

      if (this.registrationFormDependenteDetails.value.codSexo != undefined) {
        this.sexoDependente.codSexo = this.registrationFormDependenteDetails.value.codSexo;
        this.dependente.codSexo = this.sexoDependente;
      } else {
        this.sexoDependente.codSexo = 99;
        this.dependente.codSexo = this.sexoDependente;
      }

      if (this.registrationFormDependenteDetails.value.deficiencia != undefined) {
        this.deficiencia.codDeficiencia = this.registrationFormDependenteDetails.value.deficiencia;
        this.dependente.deficiencia = this.deficiencia;
      } else {
        this.deficiencia.codDeficiencia = 999;
        this.dependente.deficiencia = this.deficiencia;
      }

      if (this.registrationFormDependenteDetails.value.codParentesco != undefined) {
        this.parentesco.codParentesco = this.registrationFormDependenteDetails.value.codParentesco;
        this.dependente.codParentesco = this.parentesco;
      } else {
        this.parentesco.codParentesco = 999;
        this.dependente.codParentesco = this.parentesco;
      }

      /* TODOO OBSERVAR ALTERACAO PESSOA FISICA */
      this.pessoaFisicaDependente.cpf = this.usuarioLogado;
      this.dependente.cpfServidor = this.pessoaFisicaDependente;

      this.recadastramentoService.cadastrarDependente(this.dependente)
        .then(dependenteCadastrado => {
          if (this.file) {
            this.recadastramentoService.enviarFotoPerfilDependente(this.file, this.dependente.cpfDependente)
            .then(() => {
            })
          }
          this.carregarDependentes(this.usuarioLogado);
          this.registrationFormDependenteDetails.reset();
          this.snackBarService.error('Dependente cadastrado com sucesso.');


        })
        .catch(erro => {
          //this.errorHandlerService.handle(erro);
          this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos estão preenchidos corretamente e tente novamente');
        })
    } else {
      this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos estão preenchidos corretamente e tente novamente');
    }
  }


  adicionarNovoContato() {

    if (this.registrationFormContatoDetails.valid) {

      this.contato = this.registrationFormContatoDetails.value;


      if (this.registrationFormContatoDetails.value.contatoTipo != undefined) {
        this.contatoTipo.codContatoTipo = this.registrationFormContatoDetails.value.contatoTipo;
        this.contato.contatoTipo = this.contatoTipo;
      } else {
        this.contatoTipo.codContatoTipo = 99;
        this.contato.contatoTipo = this.contatoTipo;
      }


      /* TODOO OBSERVAR ALTERACAO PESSOA FISICA */
      this.pessoaFisicaContato.cpf = this.usuarioLogado;
      this.contato.pessoaFisica = this.pessoaFisicaContato;


      this.recadastramentoService.cadastrarContato(this.contato)
        .then(() => {
          this.registrationFormContatoDetails.reset();
          this.carregarContatosPf(this.usuarioLogado);
          this.snackBarService.sucess('Contato cadastrado com sucesso');
        })
        .catch(erro => {
          this.errorHandlerService.handle(erro);
        })
    } else {
      this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos estão preenchidos corretamente e tente novamente');
    }

  }




  editarDependente(codDependente: number) {

    this.alteraEstadoBotoesDependentes();

    this.recadastramentoService.buscarDependentesPorId(codDependente)
      .then(dependente => {
        this.codDependente = dependente.codDependente;
        this.dependente = dependente;
        this.selectedDepSexo = this.dependente.codSexo.codSexo;
        this.selectedDeficiencias = this.dependente.deficiencia.codDeficiencia;
        this.selectedDepGrauParentesco = this.dependente.codParentesco.codParentesco;
      })
      .catch(erro => {
        this.errorHandlerService.handle(erro);
      })
  }

  editarContato(codContato: number) {

    this.alteraEstadoBotoesContato()

    this.recadastramentoService.buscarContatosPorId(codContato)
      .then(contato => {
        this.codContato = contato.codContato;
        this.contato = contato;
        this.selectedSubTipo = this.contato.contatoTipo.codContatoTipo;
      })
      .catch(erro => {
        this.errorHandlerService.handle(erro);
      })
  }

  excluirContato(codContato: number) {
    this.recadastramentoService.removerContato(codContato)
      .then(() => {
        this.carregarContatosPf(this.usuarioLogado);
      })
      .catch(erro => {
        this.errorHandlerService.handle(erro);
      })

  }


  excluirDependente(codDependente: number) {
    this.recadastramentoService.removerDependente(codDependente)
      .then(() => {
        this.carregarDependentes(this.usuarioLogado);
      })
      .catch(erro => {
        this.errorHandlerService.handle(erro);
      })

  }

  atualizarContato() {

    if (this.registrationFormContatoDetails.valid) {
      this.constroiObjetoContato();

      this.recadastramentoService.atualizarContato(this.contato, this.codContato)
        .then(() => {
          this.alteraEstadoBotoesContatoCadastro();
          this.registrationFormContatoDetails.reset();
          this.carregarContatosPf(this.usuarioLogado);
          this.snackBarService.error('Contato atualizado com sucesso')
        })
        .catch(erro => {
          this.errorHandlerService.handle(erro);
        })
    } else {
      this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos estão preenchidos corretamente e tente novamente')
    }


  }

  atualizarDependente() {

    if (this.registrationFormDependenteDetails.valid) {
      this.constroiObjetoDependente()

      if (this.file) {
        this.recadastramentoService.enviarFotoPerfilDependente(this.file, this.dependente.cpfDependente)
        .then(() => {
          //this.file
        })
      }

      this.recadastramentoService.atualizarDadosDependente(this.dependente, this.codDependente)
        .then(() => {
          this.alteraEstadoBotoesDependentesCadastro();
          this.registrationFormDependenteDetails.reset();
          this.carregarDependentes(this.usuarioLogado);
          this.snackBarService.sucess('Dependente atualizado com sucesso.')
        })
        .catch(erro => {
          this.errorHandlerService.handle(erro);
        })
    } else {
      this.snackBarService.error('Ocorreu um erro ao processar sua solicitação. Verifique se os campos estão preenchidos corretamente e tente novamente')
    }

  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "400px";

    //this.dialog.open(ResetSenhaFormComponent, dialogConfig);

    const dialogRef = this.dialog.open(ResetSenhaFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      result => console.log('The dialog was closed')
    );
  }

  openDialogMensagemRecadastramento() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "800px";
    dialogConfig.width = "800px";

    const dialogRef = this.dialog.open(AtualizacaoRecadastramentoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      result => console.log('The dialog was closed')
    );
  }


  constroiObjetoPessoaFisica() {


    this.pessoaFisica = this.registrationFormPessoal.value;
    this.pessoaFisica.nis = Utils.formataStrings(this.registrationFormPessoal.value.nis);
    this.pessoaFisica.endCep = Utils.formataStrings(this.registrationFormPessoal.value.endCep);

    this.pessoaFisica.usuarioRoles = this.usuarioRole;
    this.pessoaFisica.pessoaJuridica = this.pessoaJuridica;

    this.pessoaFisica.logradouroSa = this.registrationFormPessoal.value.logradouroSa + " ";

    if (this.registrationFormPessoal.value.estadoCtpsUf != undefined) {
      this.estadoCtps.codEstado = this.registrationFormPessoal.value.estadoCtpsUf;
      this.pessoaFisica.estadoCtpsUf = this.estadoCtps;
    } else {
      this.estadoCtps.codEstado = "NA";
      this.pessoaFisica.estadoCtpsUf = this.estadoCtps;
    }

    // tira mascara
    /* if(this.registrationForm.get('pessoalDetails').value.cpf != undefined){
      this.pessoaFisica.cpf = this.registrationForm.get('pessoalDetails').value.cpf.replace(/\.|-/gm,'');
    }
    if(this.registrationForm.get('pessoalDetails').value.nis != undefined){
      this.pessoaFisica.nis = this.registrationForm.get('pessoalDetails').value.nis.replace(/\.|-/gm,'');
    }
    if(this.registrationForm.get('pessoalDetails').value.tituloEleitorNumero != undefined){
       this.pessoaFisica.tituloEleitorNumero = this.registrationForm.get('pessoalDetails').value.tituloEleitorNumero.replace(/\.|-/gm,'');
    }
    if(this.registrationForm.get('pessoalDetails').value.endCep != undefined){
       this.pessoaFisica.endCep = this.registrationForm.get('pessoalDetails').value.endCep.replace(/\.|-/gm,'');
    } */

    //this.logradouro.cep = this.registrationForm.get('pessoalDetails').value.logradouro;
    //this.pessoaFisica.logradouro = this.logradouro;

    if (this.registrationFormPessoal.value.estadoCivil != undefined) {
      this.estadoCivil.codEstadoCivil = this.registrationFormPessoal.value.estadoCivil;
      this.pessoaFisica.estadoCivil = this.estadoCivil;
    } else {
      this.estadoCivil.codEstadoCivil = 99;
      this.pessoaFisica.estadoCivil = this.estadoCivil;
    }

    if (this.registrationFormPessoal.value.grauInstrucao != undefined) {
      this.grauInstrucao.codGrauInstrucao = this.registrationFormPessoal.value.grauInstrucao;
      this.pessoaFisica.grauInstrucao = this.grauInstrucao;
    } else {
      this.grauInstrucao.codGrauInstrucao = 99;
      this.pessoaFisica.grauInstrucao = this.grauInstrucao;
    }

    if (this.registrationFormPessoal.value.identificaoOrgao != undefined) {
      this.identificacaoOrgao.codIdentificacaoOrgao = this.registrationFormPessoal.value.identificaoOrgao;
      this.pessoaFisica.identificaoOrgao = this.identificacaoOrgao;
    } else {
      this.identificacaoOrgao.codIdentificacaoOrgao = 99;
      this.pessoaFisica.identificaoOrgao = this.identificacaoOrgao;
    }

    if (this.registrationFormPessoal.value.identificacaoTipo != undefined) {
      this.identificacaoTipo.codIdentificaoTipo = this.registrationFormPessoal.value.identificacaoTipo;
      this.pessoaFisica.identificacaoTipo = this.identificacaoTipo;
    } else {
      this.identificacaoTipo.codIdentificaoTipo = 99;
      this.pessoaFisica.identificacaoTipo = this.identificacaoTipo;
    }

    if (this.registrationFormPessoal.value.estadoIdentificacaoUf != undefined) {
      this.estadoIdentificacaoUf.codEstado = this.registrationFormPessoal.value.estadoIdentificacaoUf;
      this.pessoaFisica.estadoIdentificacaoUf = this.estadoIdentificacaoUf;
    } else {
      this.estadoIdentificacaoUf.codEstado = "NA";
      this.pessoaFisica.estadoIdentificacaoUf = this.estadoIdentificacaoUf;
    }

    if (this.registrationFormPessoal.value.nacionalidade != undefined) {
      this.nacionalidade.codNacionalidade = this.registrationFormPessoal.value.nacionalidade;
      this.pessoaFisica.nacionalidade = this.nacionalidade;
    } else {
      this.nacionalidade.codNacionalidade = 105;
      this.pessoaFisica.nacionalidade = this.nacionalidade;
    }


    if (this.registrationFormPessoal.value.estadoNaturalidadeUf != undefined) {
      this.estadoNaturalidadeUf.codEstado = this.registrationFormPessoal.value.estadoNaturalidadeUf;
      this.pessoaFisica.estadoNaturalidadeUf = this.estadoNaturalidadeUf;
    } else {
      this.estadoNaturalidadeUf.codEstado = "NA";
      this.pessoaFisica.estadoNaturalidadeUf = this.estadoNaturalidadeUf;
    }

    //this.cidadeNaturalidade.codCidade = this.registrationForm.get('pessoalDetails').value.naturalidadeCidade;
    //this.pessoaFisica.naturalidadeCidade = this.cidadeNaturalidade;

    if (this.registrationFormPessoal.value.racaCor != undefined) {
      this.racaCor.codRacaCor = this.registrationFormPessoal.value.racaCor;
      this.pessoaFisica.racaCor = this.racaCor;
    } else {
      this.racaCor.codRacaCor = 99;
      this.pessoaFisica.racaCor = this.racaCor;
    }

    if (this.registrationFormPessoal.value.sexo != undefined) {
      this.sexoServidor.codSexo = this.registrationFormPessoal.value.sexo;
      this.pessoaFisica.sexo = this.sexoServidor;
    } else {
      this.sexoServidor.codSexo = 99;
      this.pessoaFisica.sexo = this.sexoServidor;
    }

    if (this.registrationFormPessoal.value.cnhExpedicaoUf != undefined) {
      this.estadoCnhUf.codEstado = this.registrationFormPessoal.value.cnhExpedicaoUf;
      this.pessoaFisica.cnhExpedicaoUf = this.estadoCnhUf;
    } else {
      this.estadoCnhUf.codEstado = "NA";
      this.pessoaFisica.cnhExpedicaoUf = this.estadoCnhUf;
    }

    if (this.registrationFormPessoal.value.reservistaExpedicaoUf != undefined) {
      this.estadoReservistaUf.codEstado = this.registrationFormPessoal.value.reservistaExpedicaoUf;
      this.pessoaFisica.reservistaExpedicaoUf = this.estadoReservistaUf;
    } else {
      this.estadoReservistaUf.codEstado = "NA";
      this.pessoaFisica.reservistaExpedicaoUf = this.estadoReservistaUf;
    }

    //this.cidadeTitulo.codCidade = this.registrationForm.get('pessoalDetails').value.codTituloCidade;
    //this.pessoaFisica.codTituloCidade = this.cidadeTitulo;
    if (this.registrationFormPessoal.value.codTituloUf != undefined) {
      this.estadoTituloUf.codEstado = this.registrationFormPessoal.value.codTituloUf;
      this.pessoaFisica.codTituloUf = this.estadoTituloUf;
    } else {
      this.estadoTituloUf.codEstado = "NA";
      this.pessoaFisica.codTituloUf = this.estadoTituloUf;
    }

    if (this.registrationFormPessoal.value.codTipoSanguineo != undefined) {
      this.tipoSanguineo.codTipoSanguineo = this.registrationFormPessoal.value.codTipoSanguineo;
      this.pessoaFisica.codTipoSanguineo = this.tipoSanguineo;
    } else {
      this.tipoSanguineo.codTipoSanguineo = 99;
      this.pessoaFisica.codTipoSanguineo = this.tipoSanguineo;
    }

    if (this.registrationFormPessoal.value.cnhCategoria != undefined) {
      this.cnhCategoria.codCnhCategoria = this.registrationFormPessoal.value.cnhCategoria;
      this.pessoaFisica.cnhCategoria = this.cnhCategoria;
    } else {
      this.cnhCategoria.codCnhCategoria = 99;
      this.pessoaFisica.cnhCategoria = this.cnhCategoria;
    }

  }


  constroiObjetoDependente() {

    //this.dependente = this.registrationFormDependenteDetails.value;
    this.dependente.cpfDependente = Utils.formataStrings(this.registrationFormDependenteDetails.value.cpfDependente);

    if (this.registrationFormDependenteDetails.value.codSexo != undefined) {
      this.sexo.codSexo = this.registrationFormDependenteDetails.value.codSexo;
      this.dependente.codSexo = this.sexo;
    } else {
      this.sexo.codSexo = 99;
      this.dependente.codSexo = this.sexo;
    }

    if (this.registrationFormDependenteDetails.value.deficiencia != undefined) {
      this.deficiencia.codDeficiencia = this.registrationFormDependenteDetails.value.deficiencia;
      this.dependente.deficiencia = this.deficiencia;
    } else {
      this.deficiencia.codDeficiencia = 999;
      this.dependente.deficiencia = this.deficiencia;
    }

    this.pessoaFisicaDependente.cpf = this.usuarioLogado;
    this.dependente.cpfServidor = this.pessoaFisicaDependente;


    this.parentesco.codParentesco = this.registrationFormDependenteDetails.value.codParentesco;
    this.dependente.codParentesco = this.parentesco;

    /*  if(this.registrationForm.get('dependenteDetails').value.cpfDependente != undefined){
       this.pessoaFisica.endCep = this.registrationForm.get('dependenteDetails').value.cpfDependente.replace(/\.|-/gm,'');
     } */

  }

  constroiObjetoContato() {

    this.contato = this.registrationFormContatoDetails.value

    if (this.registrationFormContatoDetails.value.contatoTipo != undefined) {
      this.contatoTipo.codContatoTipo = this.registrationFormContatoDetails.value.contatoTipo;
      this.contato.contatoTipo = this.contatoTipo;
    } else {
      this.contatoTipo.codContatoTipo = 99;
      this.contato.contatoTipo = this.contatoTipo;
    }

    this.pessoaFisicaContato.cpf = this.usuarioLogado;
    this.contato.pessoaFisica = this.pessoaFisicaContato;

  }

  alteraEstadoBotoesContatoCadastro() {
    this.editandoContato = false;
    this.cadastrandoContato = true;
  }

  alteraEstadoBotoesDependentesCadastro() {
    this.editandoDependente = false;
    this.cadastrandoDependente = true;
  }

  alteraEstadoBotoesContato() {
    this.editandoContato = true;
    this.cadastrandoContato = false;
  }

  alteraEstadoBotoesDependentes() {
    this.editandoDependente = true;
    this.cadastrandoDependente = false;
  }

  verificaUpdateSenha(cpf: string) {
    this.usuarioAuxService.verificaPasUpdateLogin(cpf)
      .then(resultado => {
        if (resultado) {
          this.openDialog();
        }
      })
  }



  verificaRecadastramento() {
    return this.recadastramentoService.verificarRecadastramento(this.usuarioLogado)
      .then(status => {
        if(!status) {
          this.openDialogMensagemRecadastramento();
        }
      });
  }



  alterarEstadoBotoesGravar() {
    this.mostrarBotaoProximo = false;
    this.mostrarBotaoGravar = true;
  }
}


