import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioAux } from '../domain/UsuarioAux';

@Injectable({
  providedIn: 'root'
})
export class UsuarioAuxService {

  usuarioAuxUrl: string;

  constructor(private http: HttpClient) {
    this.usuarioAuxUrl = `${environment.apiUrl}/senhas`;
  }

  recuperarConta(cpf: string, nomeMae: string, email: string) {
    let params = new HttpParams();
    params = params.set('cpf', cpf);
    params = params.set('nomeMae', nomeMae);
    params = params.set('email', email);

    return this.http.put(`${this.usuarioAuxUrl}/recuperar-conta?`, {}, { params: params })
      .toPromise();
  }

  updateSenha(cpf : string){
    return this.http.put<void>(`${this.usuarioAuxUrl}/alterar/${cpf}`, null)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  updateSenhaUsuario(cpf : string, usuarioAux : UsuarioAux){
    return this.http.put<void>(`${this.usuarioAuxUrl}/usuarios/alterar/${cpf}`, usuarioAux)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }

  verificaPasUpdateLogin(cpf : string){
    return this.http.get<Boolean>(`${this.usuarioAuxUrl}/${cpf}`)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  }
 /*  verificaPasUpdateLogin(cpf : string){
    return this.http.get<Boolean>(`${this.usuarioAuxUrl}/${cpf}`)
    .toPromise()
    .then(( response : any) => {
      return response;
    })
  } */
}
