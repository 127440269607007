import { ContatoSubTipo } from "./ContatoSubTipo";
import { ContatoTipo } from "./ContatoTipo";
import { PessoaFisica } from "./PessoaFisica";
import { PessoaJuridica } from "./PessoaJuridica";

export class Contato {

  codContato : number;
  nomeContato : string;
  contatoTipo = new ContatoTipo ();
  pessoaFisica = new PessoaFisica();

}
