import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/seguranca/auth.service';
import { SnackBarService } from 'src/app/servicos_utilitarios/snack-bar.service';
import { UsuarioAuxService } from 'src/app/servicos_utilitarios/usuario-aux.service';
import { ErrorHandlerService } from 'src/app/shared/error-handler.service';
import { Utils } from 'src/app/util/Utils';

@Component({
  selector: 'app-recuperacao-conta',
  templateUrl: './recuperacao-conta.component.html',
  styleUrls: ['./recuperacao-conta.component.scss']
})
export class RecuperacaoContaComponent implements OnInit {

  mostrarInformacoes = false;
  ocultarForm=true;
  emailInformado = '';

  recuperacaoForm: FormGroup<{
    cpf: FormControl<string>
    nomeMae: FormControl<string>
    email: FormControl<string>
  }>

  constructor(
    private formBuilder: FormBuilder,
    private usuarioAuxService: UsuarioAuxService,
    private auth: AuthService,
    private erroHandler: ErrorHandlerService,
    private snackBarService: SnackBarService
  ) {
    this.recuperacaoForm = this.formBuilder.group({
      cpf: new FormControl('', {validators:[Validators.required, Validators.nullValidator], nonNullable: true}),
      nomeMae: new FormControl('', {validators:[Validators.required, Validators.nullValidator], nonNullable: true}),
      email: new FormControl('', {validators:[Validators.required, Validators.nullValidator, Validators.email], nonNullable: true})
    })
  }

  ngOnInit(): void {
  }

  recuperarConta(){
    let email = Utils.trataEmail(this.recuperacaoForm.value.email);
    let cpf = Utils.formataStrings(this.recuperacaoForm.value.cpf);
    let nomeMae = this.recuperacaoForm.value.nomeMae;
    this.usuarioAuxService.recuperarConta(cpf, nomeMae, email)
    .then(() => {
      this.ocultarForm = false;
      this.mostrarInformacoes = true; })
    .catch(erro => {
      this.snackBarService.error(erro.error);
    })

    this.snackBarService.open('Verificando dados...')
  }

  logout() {
      const url = window.location.href;
      const separador = '/#/';

      const partes = url.split(separador);
      const primeiraParte = partes[0];

      window.location.replace(primeiraParte);
  }

}
