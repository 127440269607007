import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstadocivilService {


  estadoCivilUrl: string;

  constructor(private http: HttpClient) {
    this.estadoCivilUrl = `${environment.apiUrl}/estadocivil`;
  }

  buscarEstadoCivil(){
    return this.http.get(`${this.estadoCivilUrl}`)
      .toPromise()
      .then(( response : any) => {
        return response;
      })
  }

}
